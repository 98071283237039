import "bulma/css/bulma.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "material-design-icons/iconfont/material-icons.css";
import React, { Component } from "react";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AgrupacionesList from "./agrupacion/containers/agrupacion-list";
import Login from "./login/containers/login-container";
import NotificacionesCrear from "./notificacion/containers/crear-notificacion";
import NotificacionesList from "./notificacion/containers/notificaciones-list";
import PermisoDetail from "./permiso/containers/permiso-detail";
import PermisoList from "./permiso/containers/permiso-list";
import RolDetail from "./rol/containers/rol-detail";
import RolList from "./rol/containers/rol-list";
import UsuarioDetail from "./usuario/containers/usuario-detail";
import UsuarioList from "./usuario/containers/usuario-list";
import { signOut } from "./utils/auth";
// import formDesigner from "./plantillaProblema/formDesigner";
import PlantillaListAvance from "./PlantillaAlertaAvance/plantilla-avance";
import TaskAlerta from "./PlantillaAlertaAvance/task-alerta";
import PlantillaList from "./PlantillaFlujo/plantilla-list";
import TaskDesigner from "./PlantillaFlujo/task-designer";
import PivotGrid from "./Reportes/containers/pivotGrid";
import ReportePanel from "./Reportes/containers/reportPanel";
import AministracionAvisosDetalle from "./avisos-objects/container/Administracion-avisos-detalle";
import AdministradorGestiones from "./avisos-objects/container/administracion-avisos";
import CausasV2 from "./avisos-objects/container/causas";
import NavegacionAvisos from "./ejecucion-avisos/container/navegacion-avisos";
import PlantillaFlujov2 from "./plantilla-flujo-v2/plantilla-list";
import TaskDesignerV2 from "./plantilla-flujo-v2/task-designer";
import causas from "./problema/containers/causas";
//import CrearAviso from "./ejecucion-avisos/container/crear-aviso";
import crearConsultaMedica from "./clinica/containers/crear-consulta";
import crearFichaOcupacional from "./clinica/containers/crear-fichaOcupacional";
import ContactTracingLayout from "./contact-tracing/containers/layout";
import DashboardHome from "./dashboard-home/containers";
import AvisoDetalleTabs from "./ejecucion-avisos/container/aviso-detalle-tabs";
import crearAviso from "./ejecucion-avisos/container/crear-titulo";
import InfoTarea from "./ejecucion-avisos/container/info-tarea";
import ListaAvisos from "./ejecucion-avisos/container/lista-avisos";
import ListaTareasPendientes from "./ejecucion-avisos/container/lista-tareas";
import EtiquetaList from "./familia-etiqueta/containers/etiqueta-list";
import FamiliaList from "./familia-etiqueta/containers/familia-list";
import FormDesigner from "./form-designer/container/form-designer-container";
import FormList from "./form-designer/container/form-list";
import GestionContainer from "./gestion/containers/gestion-container";
import Grupo from "./grupo/containers/grupo";
import UsuarioGrupo from "./grupo/containers/usuario-grupo";
import KanbanGestiones from "./kanban-gestiones/containers";
import ReporteInocuidad from "./reportes-gestiones/containers/reporte-inocuidad";
import ReporteObraCivil from "./reportes-gestiones/containers/reporte-obracivil";
import SuperFiltro from "./super-filtro/containers/super-filtro2";
import Unauthorized from "./unauthorized/unauthorized";
//Perfil de usuario
import Perfil from "./perfil/containers/perfilLayout";
//import Vacaciones from "./vacaciones/containers/info-vacaciones";
import RedirectApp from "./redirect-app/redirect-app";
//clinica
import ConsultaClinica from "./clinica/containers/consultaMedica-list";
import ReporteVacunacion from "./clinica/containers/reporte-vacuna";
import FichaClinica from "./ficha-clinica/containers/ficha-clinica";
import FichaClinicaEmpleado from "./ficha-clinica/containers/ficha-clinica-empleado";
import NotificacionesLector from "./notificaciones-lector/containers/notificaciones-lector-container";
import CitasProgramadas from "./ficha-clinica/containers/citas-programadas";
/* --------------------------- Gestion documental inicio --------------------------- */
import CentrosDocumentalesCRUD from "./gestion-documental/CentrosDocumentalesCRUD";
import Correlativos from "./gestion-documental/Correlativos";
import DirectorioDocumentos from "./gestion-documental/DirectorioDocumentos";
import Entornos from "./gestion-documental/Entornos";
import PerfilFirma from "./gestion-documental/PerfilFirma";
import TiposDocumentos from "./gestion-documental/TiposDocumentos";
import VisualizarDocumento from "./gestion-documental/VisualizarDocumento";
import CodificacionDocumentos from "./gestion-documental/containers/codificacion-documentos";
/* --------------------------- Gestion documental fin --------------------------- */
//GESPILU
import VerbosMaestro from "./Gespilu/Verbos/containers/verbos-container";
import Organizacion from "./organizacion/containers/organizacion-container";
import DescriptorPuestoLaboral from "./puesto-laboral/containers/descriptor-puesto-container";
import PuestoLaboral from "./puesto-laboral/containers/puesto-laboral-container";
import TipoTurnoMaestro from "./tipo-turno/containers/tipo-turno-container";
// import TemporadaMaestro from "./temporada/containers/temporada-container"; //se traslado al menú de configuración de GESPILU
import CargaCreditos from "./Gespilu/carga-masiva/containers/carga-creditos-container";
import CargaEducacion from "./Gespilu/carga-masiva/containers/carga-educacion-container";
import ConfiguracionGespilu from "./configuracion-gespilu/containers/configuracion-gespilu-container";
import EncabezadoEscalaSalarial from "./escala-salarial/containers/encabezado-escala-container";
import EscalaSalarial from "./escala-salarial/containers/escala-container";
import BuscadorEmpleados from "./organizacion/containers/buscador-empleado-container";
import DashboarGespilu from "./organizacion/containers/dashboard-gespilu-container";
import ReportePlazasLaborales from "./organizacion/containers/reporte-plazas-laborales-container";
import DetalleMovimientoPersonal from "./reporte-movimientos-personal/containers/confirmar-movimiento-persona-container";
import ReporteMovimientos from "./reporte-movimientos-personal/containers/reporte-movimientos-personal-container";
//Competencias
import SkillLista from "./skill/containers/competencia-container";
import ConductasObservables from "./skill/containers/conducta-container";
import EtiquetaSkill from "./skill/containers/etiqueta-skill";
import NuevaSkill from "./skill/containers/skill";
import AsignarSkill from "./skill/containers/skill-empleado";
//Asistencias
import ReporteAsistencias from "./Asistencias/Containers/reporteAsistencias";
import reporteAsistenciasCosecha from "./Asistencias/Containers/reporteAsistenciasCosecha";
//Landmarks
import LandmarksAdminContainer from "./landmarks/Containers/landmarksAdmin";

import { Asistencia } from "./digitalizacion";
import {
  CrearEventoContainer,
  EditarEventoContainer,
  ListadoEventosContainer,
} from "./eventos-calendario/containers";
import {
  CentroTemasNotificaciones,
  CrearTemaContainer,
  EditarTemaContainer,
} from "./temas-celebraciones/containers";
//correo
import CorreoCompensaciones from "./correo-compensaciones/containers/correo-compensaciones-container";

//mantenimiento-preventivo
import MisDocumentos from "./gestion-documental/MisDocumentos";
import PlantillasDocs from "./gestion-documental/PlantillasDocs";
import SolicitudCrear from "./gestion-documental/SolicitudCrear";
import EquipoMantenimientoLayout from "./mantenimiento-preventivo/containers/equipo-mantenimiento-container";
import MantenimientoPreventivo from "./mantenimiento-preventivo/containers/mantenimiento-container";

import habitaciones from "./AdmonHotel/habitaciones/habitaciones";
import reserva from "./AdmonHotel/reserva/reserva";
import AsignarDocumentosUsuario from "./gestion-documental/AsignarDocumentosUsuario";
import BlogPreguntas from "./gestion-documental/BlogPreguntas";
import ControlDoc from "./gestion-documental/ControlDoc";
import DocumentosObsoletos from "./gestion-documental/DocumentosObsoletos";
import Notificaciones from "./gestion-documental/Notificaciones";
import PermisosRoles from "./gestion-documental/PermisosRoles";
import PreguntasRespuestas from "./gestion-documental/PreguntasRespuestas";
import UsuariosRoles from "./gestion-documental/UsuariosRoles";
import GesDocRoute from "./gestion-documental/componentes/utils/GesDocRoute";

//Reportes
import ReporteCat from "./Asistencias/Containers/reporteCat";
import ReporteInconsistenciaAsistencias from "./Asistencias/reporteInconsistencias/ReporteInconsistenciaAsistencias";
import ReporteLlamadasApi from "./Reportes/containers/ReporteLlamadasApi";
import reporteUniversal from "./gestion/containers/reporteUniversal";
import KanbanBoard from "./kanban_tipo_gestion/kanbanBoard";
import calificacionObligatoria from "./calificacion-gestion/calificacion-obligatoria";
import panelSoporte from "./soportePanel/panel-soporte";
import privacy from "./healt/docs/privacy";
import agreement from "./healt/docs/agreement";

class App extends Component {
  render() {
    //loadMessages(esMessages);
    //locale("es");
    const { classes } = this.props;

    return (
      <Router>
        <Route exact path="/" component={() => <Redirect to="login" />} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/usuarios" component={UsuarioList} />
        <Route exact path="/usuario/:id" component={UsuarioDetail} />
        <Route exact path="/notificaciones" component={NotificacionesList} />
        <Route exact path="/permisos" component={PermisoList} />
        <Route exact path="/permiso/:id" component={PermisoDetail} />
        <Route exact path="/roles" component={RolList} />
        <Route exact path="/rol/:id" component={RolDetail} />
        <Route exact path="/agrupaciones" component={AgrupacionesList} />
        <Route exact path="/clinica" component={ConsultaClinica} />
        <Route exact path="/citas-programadas" component={CitasProgramadas} />

        <Route
          exact
          path="/correo-compensaciones"
          component={CorreoCompensaciones}
        />
        {/* <Route exact path="/ficha-clinica" component={FichaClinica} /> */}
        <Route exact path="/ficha-clinica" component={BuscadorEmpleados} />
        <Route
          exact
          path="/mantenimiento-preventivo"
          component={MantenimientoPreventivo}
        />
        <Route
          exact
          path="/equipo-mantenimiento"
          component={EquipoMantenimientoLayout}
        />
        <Route exact path="/reporte-vacunacion" component={ReporteVacunacion} />

        {/* Asistencias */}
        <Route
          exact
          path="/reporteAsistencias"
          component={ReporteAsistencias}
        />
        <Route exact path="/reporte/asistenciasCat" component={ReporteCat} />

        {/* Landmarks */}
        <Route
          exact
          path="/landmarksAdmin"
          component={LandmarksAdminContainer}
        />

        {/* gespilu */}

        <Route exact path="/puesto-laboral" component={PuestoLaboral} />
        <Route
          exact
          path="/puesto-laboral-descriptor/:codigoPuestoLaboral"
          component={DescriptorPuestoLaboral}
        />
        <Route exact path="/organizacion" component={Organizacion} />
        <Route exact path="/dashboard-gespilu" component={DashboarGespilu} />
        <Route
          exact
          path="/reporte-plazas-laborales"
          component={ReportePlazasLaborales}
        />
        <Route exact path="/etiquetaskill" component={EtiquetaSkill} />
        <Route exact path="/lista-competencias" component={SkillLista} />
        <Route exact path="/nueva-competencia" component={NuevaSkill} />
        <Route
          exact
          path="/competencia-detalle/:codigoCompetencia"
          component={ConductasObservables}
        />

        <Route
          exact
          path="/empleado-competencia/:dpi/:codigoPuestoLaboralCompetencia"
          component={AsignarSkill}
        />
        <Route exact path="/verbos" component={VerbosMaestro} />
        <Route exact path="/tipo-turno" component={TipoTurnoMaestro} />
        {/* <Route exact path="/temporada" component={TemporadaMaestro} /> */}
        <Route
          exact
          path="/reporte-movimientos-personal"
          component={ReporteMovimientos}
        />
        <Route exact path="/buscar-empleados" component={BuscadorEmpleados} />
        <Route exact path="/carga-educacion" component={CargaEducacion} />
        <Route exact path="/carga-creditos" component={CargaCreditos} />
        <Route
          exact
          path="/configuracion-gespilu"
          component={ConfiguracionGespilu}
        />
        <Route
          exact
          path="/confirmar-movimiento-gespilu/:token"
          component={DetalleMovimientoPersonal}
        />
        <Route
          exact
          path="/escala-salarial"
          component={EncabezadoEscalaSalarial}
        />
        <Route
          exact
          path="/escala-salarial/:codigoEncabezadoEscalaSalarial"
          component={EscalaSalarial}
        />
        {/* fin gespilu */}
        <Route
          exact
          path="/notificaciones/crear"
          component={NotificacionesCrear}
        />
        <Route
          exact
          path="/notificaciones/editar"
          component={NotificacionesCrear}
        />
        <Route exact path="/home" component={DashboardHome} />
        <Route exact path="/kanban" component={KanbanGestiones} />

        <Route
          exact
          path="/logout"
          render={async () => {
            await signOut();
            window.location.href = "/login";
          }}
        />
        {/* <Route exact path="/entidad" component={EntidadesList} />
        <Route exact path="/entidad/:codigoEntidad" component={EntidadDetail} /> */}
        <Route exact path="/flujo" component={PlantillaList} />
        <Route
          exact
          path="/plantillasnotificaciones"
          component={PlantillaListAvance}
        />
        <Route
          exact
          path="/flujo/:codigoPlantillatareas"
          component={TaskDesigner}
        />
        {/* <Route
          exact
          path="/FichaClinicaEmpleado/:dpiEmpleado"
          component={FichaClinicaEmpleado}
        /> */}
        <Route
          exact
          path="/plantillasnotificaciones/:codigoPlantillaalerta"
          component={TaskAlerta}
        />
        {/* <Route exact path="/gestionprocesos/" component={GestionProcesos} /> */}
        {/* <Route
          exact
          path="/problema/:codigoProblema"
          component={formDesigner}
        /> */}
        <Route exact path="/causa" component={causas} />
        <Route exact path="/reportes" component={ReportePanel} />
        <Route exact path="/pivot" component={PivotGrid} />
        {/* Rutas para nueva version de avisos */}
        <Route
          exact
          path="/admongestiones"
          component={AdministradorGestiones}
        />
        <Route
          exact
          path="/admongestiones/:dpi"
          component={AdministradorGestiones}
        />
        {/* <Route
          exact
          path="/problema/:codigoProblema"
          component={formDesigner}
        /> */}
        <Route
          exact
          path="/tipoGestion/:codigoProblema"
          component={AministracionAvisosDetalle}
        />
        <Route
          exact
          path="/reportes/llamadasApi"
          component={ReporteLlamadasApi}
        />
        <Route exact path="/admonflujo" component={PlantillaFlujov2} />
        <Route
          exact
          path="/flujov2/:codigoPlantillatareas"
          component={TaskDesignerV2}
        />
        <Route exact path="/causasv2/:codigoSubEntidad" component={CausasV2} />
        {/* EJECUCIÓN DE AVISOS */}
        {/* <Route exact path="/bandeja" component={Bandeja} /> */}
        <Route exact path="/bandeja" component={DashboardHome} />
        <Route exact path="/navegacion" component={NavegacionAvisos} />
        <Route
          exact
          path="/aviso/nuevo/:codigoProblema"
          component={crearAviso}
        />

        <Route
          exact
          path="/aviso/nuevo/:codigoProblema/:dpi"
          component={crearAviso}
        />
        <Route
          exact
          path="/deleteuserdata/:codigoProblema"
          component={crearAviso}
        />
        <Route
          exact
          path="/vacaciones/nuevo/:codigoProblema/:dpi"
          component={crearAviso}
        />
        <Route
          exact
          path="/clinica/nuevo/:dpi"
          component={crearConsultaMedica}
        />
        <Route
          exact
          path="/clinica/fichaocupacional/:dpi"
          component={crearFichaOcupacional}
        />
        <Route exact path="/ListaAvisos/:tipo" component={ListaAvisos} />
        <Route
          exact
          path="/ListaTareasPendientes/"
          component={ListaTareasPendientes}
        />
        <Route
          exact
          path="/avisodetalle/:codigoAviso"
          component={AvisoDetalleTabs}
        />

        <Route exact path="/contacttracing/" component={ContactTracingLayout} />

        <Route exact path="/grupo/" component={Grupo} />
        <Route
          exact
          path="/usuarioGrupo/:codigoGrupo"
          component={UsuarioGrupo}
        />
        <Route
          exact
          path="/gestion/:codigoGestion"
          component={GestionContainer}
        />
        <Route exact path="/infotarea/:codigoTarea" component={InfoTarea} />
        <Route exact path="/superfiltro" component={SuperFiltro} />
        <Route exact path="/superfiltro/:tipoGestion" component={SuperFiltro} />

        <Route exact path="/reporteObraCivil" component={ReporteObraCivil} />
        <Route exact path="/reporteInocuidad" component={ReporteInocuidad} />

        <Route exact path="/familiaetiqueta" component={FamiliaList} />

        <Route
          exact
          path="/familia/:codigoFamiliaEtiqueta"
          component={EtiquetaList}
        />

        <Route exact path="/unauthorized" component={Unauthorized} />

        <Route exact path="/formdesigner" component={FormList} />
        <Route exact path="/formdesigner/:codigo" component={FormDesigner} />

        <Route exact path="/perfil" component={Perfil} />
        {/* <Route exact path="/vacaciones" component={Vacaciones} /> */}
        <Route
          exact
          path="/redirectapp/:codigoGestion"
          component={RedirectApp}
        />
        <Route
          exact
          path="/notificacionesPorUsuario"
          component={NotificacionesLector}
        />

        {/* --------------------------- Gestion documental inicio --------------------------- */}
        <GesDocRoute exact path="/documentos/entornos" component={Entornos} />
        <GesDocRoute
          exact
          path="/documentos/directorio"
          component={DirectorioDocumentos}
        />
        <GesDocRoute
          exact
          path="/documentos/tipos"
          component={TiposDocumentos}
        />
        <GesDocRoute
          exact
          path="/documentos/codificacion-documentos"
          component={CodificacionDocumentos}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.CreacionBorradores"
          path="/documentos/solicitudes/crear"
          component={SolicitudCrear}
        />
        <GesDocRoute
          exact
          path="/documentos/correlativos"
          component={Correlativos}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.AdminCentrosDocumentales"
          path="/documentos/centros-documentales"
          component={CentrosDocumentalesCRUD}
        />
        <Route exact path="/documentos/perfil/:id" component={PerfilFirma} />
        <GesDocRoute
          exact
          path="/documentos/mis-documentos"
          component={MisDocumentos}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.AdminPlantillas"
          path="/documentos/plantillas"
          component={PlantillasDocs}
        />
        <GesDocRoute
          exact
          path="/doc/proceso/:proceso/codigo/:codigo"
          component={VisualizarDocumento}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.TransferenciaDocumentos"
          path="/documentos/transferir"
          component={AsignarDocumentosUsuario}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.AdminNotificaciones"
          path="/documentos/notificaciones"
          component={Notificaciones}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.DirectorioObsoletos"
          path="/documentos/obsoletos"
          component={DocumentosObsoletos}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.Usuarios"
          path="/documentos/permisos"
          component={PermisosRoles}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.Usuarios"
          path="/documentos/roles"
          component={UsuariosRoles}
        />
        <GesDocRoute
          exact
          keyPermiso="GESDOC.Usuarios"
          path="/documentos/control"
          component={ControlDoc}
        />
        <GesDocRoute
          exact
          path="/documentos/blog-preguntas"
          component={BlogPreguntas}
        />
        <Route
          exact
          path="/documentos/preguntas"
          component={PreguntasRespuestas}
        />
        {/* --------------------------- Gestion documental fin --------------------------- */}

        <Route
          exact
          path="/notificacionesPorUsuario/:codigo"
          component={NotificacionesLector}
        />

        <Route exact path="/digitalizacion/asistencia" component={Asistencia} />

        <Route
          exact
          path="/eventos-calendario"
          component={ListadoEventosContainer}
        />
        <Route
          exact
          path="/eventos-calendario/crear"
          component={CrearEventoContainer}
        />
        <Route
          exact
          path="/eventos-calendario/:id/editar"
          component={EditarEventoContainer}
        />

        <Route
          exact
          path="/centro-temas-notificaciones"
          component={CentroTemasNotificaciones}
        />
        <Route
          exact
          path="/temas-notificaciones/crear"
          component={CrearTemaContainer}
        />
        <Route
          exact
          path="/temas-notificaciones/:id/editar"
          component={EditarTemaContainer}
        />
        <Route exact path="/hotel/reserva/" component={reserva} />
        <Route exact path="/hotel/habitaciones/" component={habitaciones} />
        <Route
          exact
          path="/reporte/inconsistenciaAsistencia/"
          component={ReporteInconsistenciaAsistencias}
        />
        <Route exact path="/reporteUniversal" component={reporteUniversal} />
        <Route
          exact
          path="/reporte/asistenciasCosecha/"
          component={reporteAsistenciasCosecha}
        />
        <Route exact path="/kanbantg" component={KanbanBoard} />

        <Route exact path="/calificar" component={calificacionObligatoria} />

        <Route exact path="/moxipanel" component={panelSoporte} />
        <Route exact path="/healtprivacypolicy" component={privacy} />
        <Route exact path="/healtagreement" component={agreement} />
      </Router>
    );
  }
}

export default App;
