import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Fab, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { Chip, Backdrop, CircularProgress } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import LinearProgress from "@material-ui/core/LinearProgress";

//Appbar
import { fade } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
//Componentes Ficha Clinica
import PerfilComponente from "../../perfil/containers/perfilComponenteHorizontal";
import UsuariosList from "../../avisos-objects/components/usuarios-list";
import { Search } from "@material-ui/icons";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
  },
})(Backdrop);

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const FichaClinica = () => {
  const classes = useStyles();
  const [buscando, setBuscando] = useState(false);
  const [dpi, setDpi] = useState("");
  const [textoValor, setTextoValor] = useState("");
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [encontrado, setEncontrado] = useState(false);
  const [openAsignarUsuarios, setOpenAsignarUsuarios] = useState(true);
  const [listaUsuariosModal, setListaUsuariosModal] = useState([]);
  const [txtTextoBusquedaUsuario, setTxtTextoBusquedaUsuario] = useState("");
  // const buscar = async () => {
  //   const session = await isSignedIn();
  //   if (!session.response) {
  //     window.location.href = "/login";
  //     return;
  //   }

  //   try {
  //     setBuscando(true);
  //     setEncontrado(false);

  //     if (textoValor === "" || textoValor.length !== 13) {
  //       toast.error("Ingrese un DPI válido");
  //       setBuscando(false);
  //       return;
  //     } else {
  //       setDpi(textoValor);
  //       setEncontrado(true);
  //     }

  //     // let uri = "api/usuario/buscar/" + dpi;

  //     // const request = await API.fetchGetRequest(uri);

  //     // if (request.status === 401) {
  //     //   //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
  //     //   if (await signOut()) {
  //     //     window.location.url = "/Login";
  //     //   }
  //     //   return false;
  //     // } else {
  //     //   console.log(request.data.data);
  //     //   if (request.data.response) {
  //     //     if (request.data.usuarios.length === 0) {
  //     //       toast.error("No se encontró al empleado");
  //     //       return;
  //     //     }
  //     //     setDataEmpleado(request.data.usuarios[0]);
  //     //     setEncontrado(true);
  //     //   } else {
  //     //     toast.error("No se encontró al empleado");
  //     //   }
  //     // }
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setBuscando(false);
  //   }
  // };

  const handleChange = (event) => {
    setTextoValor(event.target.value);
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     buscar();
  //   }
  // };

  const openModalAsignarUsuarios = () => {
    setOpenAsignarUsuarios(true);
  };

  const closeModalAsignarUsuarios = () => {
    setOpenAsignarUsuarios(false);
  };

  const seleccionarUsuario = async (codigoUsuario, usuario) => {
    console.log("Usuario seleccionado: ", usuario);
    if (usuario !== "") {
      const fila = listaUsuariosModal.filter(
        (fila) => fila.Usuario === usuario
      );
      if (Array.isArray(fila) && fila.length > 0) {
        const dpiValor = fila[0].Usuario;
        // setDpi(fila[0].Usuario);
        if (dpiValor === "" || dpiValor.length !== 13) {
          toast.error("Ingrese un usuario válido");
          setBuscando(false);
          return;
        } else {
          setDpi(dpiValor);
          setEncontrado(true);
        }
        // buscar();
      }

      setOpenAsignarUsuarios(false);
    }
  };

  const cargarListaUsuariosModal = async () => {
    let criterio = txtTextoBusquedaUsuario;
    const request = await API.fetchGetRequest(
      `api/empleado/buscar/${criterio}`
    );
    try {
      if (Array.isArray(request.data.usuarios)) {
        console.log("usuariosModal", request.data.usuarios);
        setListaUsuariosModal(request.data.usuarios);
      } else {
        setListaUsuariosModal([]);
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llavePagina = "GESPILU.Perfil.Ver";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS
    };

    fetchData();
  }, []);

  return (
    <Layout titulo={"Búsqueda de Empleados"} maxWidth="100%">
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="100%">
        {encontrado === true && (
          <Paper id="cuadroBusqueda" className={classes.paper}>
            <PerfilComponente dpi={dpi} />
          </Paper>
        )}
      </Container>
      <Fab
        color="primary"
        onClick={openModalAsignarUsuarios}
        variant="extended"
        style={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <Search className={classes.extendedIcon} />
        Búsqueda
      </Fab>
      <Modal
        aria-labelledby="Asignar usuarios"
        aria-describedby="Asignar usuarios"
        open={openAsignarUsuarios}
        onClose={closeModalAsignarUsuarios}
      >
        <div
          style={{
            top: `${50}%`,
            left: `${50}%`,
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paperUserModal}
        >
          <PerfectScrollbar>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Selecciona un usuario
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtTextoBusquedaUsuario"
                    label="Criterio de búsqueda"
                    className={classes.textField}
                    value={txtTextoBusquedaUsuario}
                    onChange={(event) => {
                      setTxtTextoBusquedaUsuario(event.target.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        cargarListaUsuariosModal();
                        ev.preventDefault();
                      }
                    }}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#217ecf",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={cargarListaUsuariosModal}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} sm={12}>
                <Grid container spacing={1}>
                  <UsuariosList
                    registros={listaUsuariosModal}
                    seleccionarUsuario={seleccionarUsuario}
                  />
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
        </div>
      </Modal>
    </Layout>
  );
};

export default withRouter(FichaClinica);
