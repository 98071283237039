import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { useParams } from "react-router";
import { Button, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";

//Appbar
import { fade } from "@material-ui/core/styles";

//Own components
import Layout from "../../layout/containers/Layout";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";

//Componentes Ficha Clinica
import DatosEmpleado from "../components/datos-empleado";
import ComponenteFichaClinicaEmpleado from "../components/dato-ficha-clinica";
import DevExpressDataGrid from "../../super-filtro/components/devexpress-data-grid";
import DatosEmpleadoVacuna from "../../perfil/components/empleado-vacuna";

import DatosEmpleadoEditar from "../../perfil/components/dato-empleado-editar";
const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,

    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const FichaClinicaEmpleado = (props) => {
  // const { dpiEmpleado } = props;
  const [usuarioAfectado, setUsuarioAfectado] = useState("");
  const [userName, setUserName] = useState("");
  const [codigoUsuarioActual, setCodigoUsuarioActual] = useState("");
  const [buscando, setBuscando] = useState(false);
  const [encontrado, setEncontrado] = useState(false);
  const [dataEmpleado, setDataEmpleado] = useState(props.dataEmpleado);

  const [dpi, setDpi] = useState("0");
  const [acceso, setAcceso] = useState(false);
  const [tab, setTab] = useState(0);
  const [listaGestiones, setListaGestiones] = useState([]);
  const [esClinica, setEsClinica] = useState(true);

  const classes = styles();

  useEffect(() => {
    async function fetchData() {
      //const dpiEmpleado  = props.dpiEmpleado;

      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.FichaClinica";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      const session = await isSignedIn();

      if (
        props.dataEmpleado.DPI === null ||
        props.dataEmpleado.DPI === undefined
      ) {
        //window.location = "/home";
        return;
      }
      if (session.response) {
        //console.log(session);

        setDpi(props.dataEmpleado.DPI);
        setCodigoUsuarioActual(session["codigoUsuario"]);
        setUsuarioAfectado(parseInt(session["codigoUsuario"]));
        setUserName(session["userName"]);
        setEncontrado(true);

        // await buscar();
        // await buscarConsultas();
      } else {
        window.location.href = "/login";
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await buscarConsultas();
    }

    fetchData();
  }, [dpi]);

  const buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      setBuscando(true);
      setEncontrado(false);
      let uri = "api/fichaClinica/empleado/" + dpi;

      console.log("Parametros");
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setDataEmpleado(request.data.data[0]);
        setEncontrado(true);
        console.log("Respuesta filtro", request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const buscarConsultas = async () => {
    try {
      setBuscando(true);

      const requestCodGestionClinica = await API.fetchGetRequest(
        `api/fichaClinica/getCodigoProblemaClinica`
      );
      const verificarGestionClinica = requestCodGestionClinica.data.data;

      let uri =
        "api/fichaClinica/filtro/" + dpi + "/" + verificarGestionClinica;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        if (Array.isArray(request.data.data)) {
          // try {
          //   //reemplaza el valor de la llave FechaVencimiento
          //   for (let i = 0; i < request.data.data.length; i++) {
          //     //Prueba de fechas
          //     // let fecha = new Date("2021-03-19T00:00:00");
          //     // let nuevaFecha = moment(fecha).format("DD/MM/YYYY");
          //     // console.log("nueva fecha", nuevaFecha);
          //     // console.log(
          //     //   "fechaVencimiento original",
          //     //   request.data.data[i].FechaVencimiento
          //     // );
          //     // console.log(
          //     //   "fechaVencimiento con formato",
          //     //   moment(new Date(request.data.data[i].FechaVencimiento)).format(
          //     //     "DD/MM/YYYY"
          //     //   )
          //     // );

          //     request.data.data[i].FechaVencimiento =
          //       request.data.data[i].FechaVencimiento == null
          //         ? null
          //         : moment(
          //             new Date(request.data.data[i].FechaVencimiento)
          //           ).format("DD/MM/YYYY");
          //   }

          // } catch (err) {
          //   console.error("format date ", err);
          // }

          //Encuentra tipos de gestión(problemas) diferentes
          console.log("Respuesta filtro", request.data.data);
          let gruposTipoGestion = [
            ...new Set(request.data.data.map((x) => x.Problema)),
          ];
          gruposTipoGestion = [
            ...new Set(gruposTipoGestion.map((x) => x.CodigoProblema)),
          ];

          //crea array de objetos tipo problema "Tipo de gestión"
          let listaObjetosTipoGestion = [];
          await gruposTipoGestion.map((item, i) => {
            let gestiones = request.data.data.filter((element) => {
              return element.CodigoProblema === item;
            });
            if (gestiones.length > 0) {
              let problemaObject = gestiones[0].Problema;
              problemaObject.Gestiones = gestiones;
              listaObjetosTipoGestion.push(problemaObject);
            }
          });

          setListaGestiones(request.data.data);
          // this.setState({
          //   grupoGestionesResult: listaObjetosTipoGestion,
          //   listaGestiones: request.data.data,
          // });

          // console.log("Grupos", gruposTipoGestion);
          // console.log("Grupos Objeto", listaObjetosTipoGestion);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const onHandleChangeDatosEmpleado = async (nuevoDataEmpleado) => {
    setDataEmpleado(nuevoDataEmpleado);
  };

  return (
    <React.Fragment>
      <Backdrop open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Grid container style={{ width: "100% !important" }}>
          {encontrado && (
            <Container maxWidth="xl">
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, val) => setTab(val)}
              >
                <Tab label="Consultas" {...a11yProps(0)} />
                <Tab label="Ficha Clínica" {...a11yProps(1)} />
                <Tab label="Vacunación" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={tab} index={1}>
                <Box className={classes.box}>
                  <ComponenteFichaClinicaEmpleado
                    dpiEmpleado={dataEmpleado.DPI}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={tab} index={0}>
                <Box className={classes.box}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      color: "white",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => {
                      // this.props.history.push("/aviso/nuevo/288");
                      window.open(
                        "/clinica/nuevo/" + dataEmpleado.DPI,
                        "_blank"
                      );
                    }}
                  >
                    Crear Consulta
                  </Button>
                  {listaGestiones.length > 0 && (
                    <DevExpressDataGrid
                      filas={listaGestiones}
                      esClinica={esClinica}
                    />
                  )}
                  {listaGestiones.length < 1 && !buscando && (
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#b2bec3",
                      }}
                    >
                      No hay resultados para mostrar.
                    </div>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Box className={classes.box}>
                  {dataEmpleado !== undefined && (
                    <DatosEmpleadoVacuna dataEmpleado={dataEmpleado} />
                  )}
                </Box>
              </TabPanel>
              <br />
              <br />
            </Container>
          )}
        </Grid>
      </Container>
      <ToastContainer />
    </React.Fragment>
  );
};

//const FichaClinicaEmpleadoContainer = withStyles(styles)(FichaClinicaEmpleado);
export default withRouter(FichaClinicaEmpleado);
