// import {
//   Box,
//   Button,
//   Checkbox,
//   CircularProgress,
//   Dialog,
//   DialogContent,
//   Divider,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   Icon,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Select,
//   Tooltip,
//   Typography,
//   makeStyles,
// } from "@material-ui/core";
// import { green } from "@material-ui/core/colors";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import axios from "axios";
// import DataGrid, {
//   Column,
//   ColumnChooser,
//   Export,
//   FilterRow,
//   GroupItem,
//   GroupPanel,
//   Grouping,
//   HeaderFilter,
//   LoadPanel,
//   MasterDetail,
//   OperationDescriptions,
//   Paging,
//   Scrolling,
//   SearchPanel,
//   StateStoring,
//   Summary,
// } from "devextreme-react/data-grid";
// import ExcelJS from "exceljs/dist/exceljs";
// import { saveAs } from "file-saver";
// import MaterialTable from "material-table";
// import React, { useEffect, useRef, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import API from "../../utils/api";
// import { isSignedIn } from "../../utils/auth";

// const LLAVE_ADJUNTOS_AVISO = "IMAGE_REPOSITORY_AVISOS";

// const ReporteUniversalGrid = ({
//   FechaInicio,
//   FechaFin,
//   TipoGestion,
//   IncluirTareas,
// }) => {
//   const classes = styles();
//   const [loading, setLoading] = useState(false);
//   const [headersDinamicos, setHeadersDinamicos] = useState([]);
//   const [headersDinamicosAsistencia, setHeadersDinamicosAsistencia] = useState(
//     []
//   );
//   const [tieneFormularioAsistencia, setTieneFormularioAsistencia] =
//     useState(false);
//   const [avisos, setAvisos] = useState([]);
//   const [summaryColumns, setSummaryColumns] = useState([]);
//   const [selectedColumn, setSelectedColumn] = useState("");
//   const [summaryType, setSummaryType] = useState("");
//   const [showInGroupFooter, setShowInGroupFooter] = useState(false);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [selectedFoto, setSelectedFoto] = useState("");
//   const [modalGridVisible, setModalGridVisible] = useState(false);
//   const [datosGrid, setDatosGrid] = useState({});

//   useEffect(() => {
//     const fetchData = async () => {
//       console.log("cargando");
//       setLoading(true);
//       let uri = `api/reporteUniversal/getAvisosDeTipo?inicio=${FechaInicio}&fin=${FechaFin}&tipoGestion=${TipoGestion}&incluirTareas=${IncluirTareas}`;
//       const request = await API.fetchGetRequest(uri);
//       if (request.data.response) {
//         setAvisos(request.data.data.Avisos);
//         setHeadersDinamicos(request.data.data.CamposEncabezado);
//         setTieneFormularioAsistencia(
//           request.data.data.TieneFormularioAsistencia
//         );
//         setHeadersDinamicosAsistencia(
//           request.data.data.CamposEncabezadoAsistencia
//         );
//         console.log(request.data.data.TieneFormularioAsistencia);
//       } else {
//         console.log(request.data.mensaje);
//         toast.error("Ocurrió un error al cargar los datos.");
//       }
//       setLoading(false);
//       console.log(request.data.data);
//     };
//     try {
//       fetchData();
//     } catch (error) {
//       console.log(error);
//       toast.error("Ocurrió un error al cargar los datos.");
//     }
//   }, [FechaInicio, FechaFin, TipoGestion]);

//   useEffect(() => {
//     const storedSummaryColumns = localStorage.getItem(
//       `${TipoGestion}summaryColumns`
//     );
//     if (storedSummaryColumns) {
//       setSummaryColumns(JSON.parse(storedSummaryColumns));
//     }
//   }, []);

//   //Para descargar los adjuntos, se necesita acceder a través de la api y con token
//   async function newTabAuth({ fileName, key }) {
//     const session = await isSignedIn();
//     const baseApi = await API.baseApi();

//     axios({
//       url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
//       method: "GET",
//       headers: { Authorization: "bearer " + session.accessToken },
//       responseType: "blob", // important
//     }).then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", fileName); //or any other extension
//       document.body.appendChild(link);
//       link.click();
//     });
//   }

//   const handleAddSummary = () => {
//     const newSummaryColums = [
//       ...summaryColumns,
//       { column: selectedColumn, summaryType, showInGroupFooter },
//     ];
//     setSummaryColumns(newSummaryColums);
//     localStorage.setItem(
//       `${TipoGestion}summaryColumns`,
//       JSON.stringify(newSummaryColums)
//     );
//     setModalVisible(false);
//   };

//   const handleRemoveSummary = (column) => {
//     const newSummaryColums = summaryColumns.filter(
//       (summaryColumn) => summaryColumn.column !== column
//     );
//     setSummaryColumns(newSummaryColums);
//     localStorage.setItem(
//       `${TipoGestion}summaryColumns`,
//       JSON.stringify(newSummaryColums)
//     );
//   };

//   const handleSummaryTypeChange = (target) => {
//     setSummaryType(target.target.value);
//   };

//   const handleShowInGroupFooterChange = (e) => {
//     setShowInGroupFooter(e.target.checked);
//   };

//   const handleColumnSelect = (target) => {
//     //Verificar si la columna seleccionada ya tiene un resumen
//     const existe = summaryColumns.find(
//       (summaryColumn) => summaryColumn.column === target.target.value
//     );
//     if (existe) {
//       toast.error("La columna seleccionada ya tiene un resumen.");
//       return;
//     }
//     setSelectedColumn(target.target.value);
//     setModalVisible(true);
//   };

//   const handleModalCancel = () => {
//     setSelectedColumn(null);
//     setSummaryType("count");
//     setShowInGroupFooter(false);
//     setModalVisible(false);
//   };

//   const dataGrid = useRef(null);

//   const resetDataGrid = () => {
//     dataGrid.current.instance.state(null);
//   };

//   const cellRender = (data) => {
//     const foto = data.value;
//     if (!foto || foto === "") {
//       return <div style={{ width: "50px" }}>-</div>;
//     }
//     return (
//       <div onClick={() => handleFotoClick(foto)} style={{ cursor: "pointer" }}>
//         <img src={foto} alt="Empleado" style={{ width: "50px" }} />
//       </div>
//     );
//   };

//   const handleFotoClick = (foto) => {
//     setSelectedFoto(foto);
//   };

//   const handleFotoDialogClose = () => {
//     setSelectedFoto(null);
//   };

//   const handleGridModalClick = (row) => {
//     console.log(row);
//     let filas = [...row.Filas];
//     //Obtener columnas tipo hora
//     const horas = row.Items.filter((x) => x.tipoCampo === 14);
//     const titulos = horas.forEach((campoHora, i) => {
//       console.log("label", campoHora.title);
//       console.log("hora pura", campoHora);

//       //reemplaza valor de campos de fecha de cada fila
//       filas.forEach((fila, i) => {
//         if (
//           fila[campoHora.title] !== null &&
//           fila[campoHora.title].hasOwnProperty("horas") &&
//           fila[campoHora.title].hasOwnProperty("minutos")
//         ) {
//           fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
//             fila[campoHora.title].minutos
//           }`;
//         } else {
//           // fila[campoHora.title] = null
//         }
//       });
//     });
//     const data = {
//       Filas: filas,
//       Cols: row.Items,
//       Nombre: row.Nombre,
//     };
//     console.log("Datos de grid", data);
//     setDatosGrid(data);
//     setModalGridVisible(true);
//   };

//   const handleGridModalClickParent = (row, title, dataa) => {
//     console.log(dataa);
//     let filas = [...row.Filas];
//     //Obtener columnas tipo hora
//     const horas = row.Items.filter((x) => x.tipoCampo === 14);
//     const titulos = horas.forEach((campoHora, i) => {
//       console.log("label", campoHora.title);
//       console.log("hora pura", campoHora);

//       //reemplaza valor de campos de fecha de cada fila
//       filas.forEach((fila, i) => {
//         if (
//           fila[campoHora.title] !== null &&
//           fila[campoHora.title].hasOwnProperty("horas") &&
//           fila[campoHora.title].hasOwnProperty("minutos")
//         ) {
//           fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
//             fila[campoHora.title].minutos
//           }`;
//         } else {
//           // fila[campoHora.title] = null
//         }
//       });
//     });
//     const data = {
//       Filas: filas,
//       Cols: row.Items,
//       Nombre: row.Nombre,
//     };
//     console.log("Datos de grid", data);
//     setDatosGrid(data);
//     setModalGridVisible(true);
//   };

//   const getGridDataFromRow = (row) => {
//     console.log(row);
//     let filas = [...row.Filas];
//     //Obtener columnas tipo hora
//     const horas = row.Items.filter((x) => x.tipoCampo === 14);
//     const titulos = horas.forEach((campoHora, i) => {
//       console.log("label", campoHora.title);
//       console.log("hora pura", campoHora);

//       //reemplaza valor de campos de fecha de cada fila
//       filas.forEach((fila, i) => {
//         if (
//           fila[campoHora.title] !== null &&
//           fila[campoHora.title].hasOwnProperty("horas") &&
//           fila[campoHora.title].hasOwnProperty("minutos")
//         ) {
//           fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
//             fila[campoHora.title].minutos
//           }`;
//         } else {
//           // fila[campoHora.title] = null
//         }
//       });
//     });
//     const data = {
//       Filas: filas,
//       Cols: row.Items,
//       Nombre: row.Nombre,
//     };
//     console.log("Datos de grid", data);

//     return data;
//   };

//   const onExporting = (e) => {
//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet("Datos");

//     // Define the main headers for your grid (adjust as needed)
//     const mainHeaders = e.component
//       .getVisibleColumns()
//       .map((col) => col.caption);

//     // Insert the headers as the first row in the worksheet
//     worksheet.addRow(mainHeaders).eachCell((cell, colNumber) => {
//       cell.font = { bold: true };
//       cell.border = {
//         top: { style: "thin" },
//         left: { style: "thin" },
//         bottom: { style: "thin" },
//         right: { style: "thin" },
//       };
//       cell.alignment = {
//         wrapText: true,
//         vertical: "middle",
//         horizontal: "center",
//       };

//       // Set a wider column width
//       worksheet.getColumn(colNumber).width = 20;
//     });

//     // Determine the range for the auto filter
//     const totalColumns = mainHeaders.length;
//     const lastColumnLetter = worksheet.getColumn(totalColumns).letter;

//     // Add auto filter to the header row
//     worksheet.autoFilter = {
//       from: "A1",
//       to: `${lastColumnLetter}1`, // Calculate the correct last column letter dynamically
//     };

//     let currentRowIndex = 2; // Start after the header

//     // Iterate over each main row in the grid
//     e.component.getVisibleRows().forEach((row) => {
//       const rowData = row.data;

//       // Insert the main row into the worksheet
//       const mainRowData = e.component
//         .getVisibleColumns()
//         .map((col) => rowData[col.dataField]);
//       const masterRow = worksheet.addRow(mainRowData);
//       masterRow.outlineLevel = 0; // No indent for the main row

//       // Move to the next row after inserting the main row
//       currentRowIndex++;

//       // Iterate over headersDinamicos to insert child tables for this main row
//       headersDinamicos.forEach((headerGroup) => {
//         if (Array.isArray(headerGroup.HeadersHijos)) {
//           headerGroup.HeadersHijos.forEach((header) => {
//             if (header.TipoCampo === 20 && rowData[header.Nombre]) {
//               const childData = getGridDataFromRow(rowData[header.Nombre]);

//               // Insert child headers
//               const childHeaders = [
//                 "",
//                 ...childData.Cols.map((col) => col.title),
//               ]; // Indented to start from column B
//               const newHeaderRow = worksheet.addRow(childHeaders);
//               newHeaderRow.outlineLevel = 1; // Indent the child headers
//               newHeaderRow.eachCell((cell, colNumber) => {
//                 if (colNumber > 1) {
//                   cell.font = { bold: true };
//                   cell.fill = {
//                     type: "pattern",
//                     pattern: "solid",
//                     fgColor: { argb: "FFCCCCCC" },
//                   };
//                   cell.border = {
//                     top: { style: "thin" },
//                     left: { style: "thin" },
//                     bottom: { style: "thin" },
//                     right: { style: "thin" },
//                   };
//                 }
//               });

//               currentRowIndex++;

//               // Insert child rows
//               childData.Filas.forEach((childRow) => {
//                 const childRowData = [
//                   "",
//                   ...childData.Cols.map((col) => childRow[col.title]),
//                 ]; // Indented to start from column B
//                 const newRow = worksheet.addRow(childRowData);
//                 newRow.outlineLevel = 1; // Indent the child rows
//                 newRow.eachCell((cell, colNumber) => {
//                   if (colNumber > 1) {
//                     cell.border = {
//                       top: { style: "thin" },
//                       left: { style: "thin" },
//                       bottom: { style: "thin" },
//                       right: { style: "thin" },
//                     };
//                   }
//                 });

//                 currentRowIndex++;
//               });

//               // Insert an empty row for separation
//               const newRow = worksheet.addRow([""]);
//               newRow.outlineLevel = 1; // Indent the empty row

//               currentRowIndex++;
//             }
//           });
//         }
//       });
//     });

//     worksheet.properties.outlineProperties = {
//       summaryBelow: false,
//       summaryRight: false,
//     };

//     //get formatted date for the file name in format DD-MM-YY
//     const date = new Date();
//     const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;

//     // Write the workbook to a buffer and save it
//     workbook.xlsx.writeBuffer().then((buffer) => {
//       saveAs(
//         new Blob([buffer], { type: "application/octet-stream" }),
//         `Datos ${formattedDate} - ${TipoGestion}.xlsx`
//       );
//     });

//     e.cancel = true; // Prevent default export behavior
//   };

//   const agregarBotonesExtras = (e) => {
//     e.toolbarOptions.items.unshift(
//       {
//         location: "after",
//         widget: "dxButton",
//         options: {
//           icon: "refresh",
//           onClick: resetDataGrid,
//           hint: "Reiniciar filtros y agrupaciones",
//         },
//       },
//       {
//         widget: "dxButton",
//         options: {
//           icon: "exportxlsx",
//           hint: "Exportar con subgrupos",
//           onClick: () => {
//             e.component.exportToExcel(false);
//           },
//         },
//         location: "after",
//       }
//     );
//   };

//   return (
//     <React.Fragment>
//       {loading ? (
//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <CircularProgress style={{ marginBottom: "20px" }} />
//           <br />
//           <br />
//         </div>
//       ) : (
//         <React.Fragment>
//           <Box className={classes.box}>
//             <DataGrid
//               dataSource={avisos}
//               showBorders={true}
//               ref={dataGrid}
//               columnAutoWidth={true}
//               wordWrapEnabled={true}
//               showRowLines={true}
//               rowAlternationEnabled={true}
//               allowColumnResizing={true}
//               columnResizingMode="widget"
//               onToolbarPreparing={agregarBotonesExtras}
//               onExporting={onExporting}
//               height={"80vh"}
//               allowColumnReordering={true}
//               groupPanel={{
//                 visible: true,
//                 emptyPanelText:
//                   "Arrastre un encabezado aquí para agrupar por esa columna",
//               }}
//             >
//               <LoadPanel
//                 height={100}
//                 width={250}
//                 indicatorSrc="https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
//               />

//               <Grouping autoExpandAll={false} contextMenuEnabled={true} />
//               <GroupPanel visible={true} />
//               <Scrolling
//                 mode="virtual"
//                 rowRenderingMode="virtual"
//                 scrollByThumb={true}
//               />
//               <Paging enabled={false} />
//               <StateStoring
//                 enabled={true}
//                 type="localStorage"
//                 storageKey={
//                   `${TipoGestion}DataGrid` + (IncluirTareas ? "ConTareas" : "")
//                 }
//               />
//               <FilterRow visible={true}>
//                 <OperationDescriptions
//                   between="Entre"
//                   contains="Contiene"
//                   endsWith="Termina con"
//                   equal="Igual"
//                   greaterThan="Mayor que"
//                   greaterThanOrEqual="Mayor o igual que"
//                   lessThan="Menor que"
//                   lessThanOrEqual="Menor o igual que"
//                   notContains="No contiene"
//                   notEqual="No igual"
//                   startsWith="Empieza con"
//                 />
//               </FilterRow>
//               <HeaderFilter visible={true} />
//               <SearchPanel visible={true} width={240} placeholder="Buscar..." />

//               {headersDinamicos.map((header) => {
//                 if (header.EsHeaderPadre) {
//                   return (
//                     <Column
//                       key={header.Nombre}
//                       //dataField={header.Nombre}
//                       caption={header.Descripcion}
//                       alignment={"center"}
//                       allowEditing={false}
//                       headerCellRender={(headerData) => {
//                         return (
//                           <div
//                             style={{
//                               fontWeight: "bold",
//                               fontSize: "1.2em",
//                               color: "black",
//                             }}
//                           >
//                             {headerData.column.caption}
//                           </div>
//                         );
//                       }}
//                     >
//                       {header.HeadersHijos.map((childHeader) => {
//                         if (childHeader.Nombre.includes("Adjuntos")) {
//                           return (
//                             <Column
//                               key={childHeader.Nombre}
//                               dataField={childHeader.Nombre}
//                               caption={childHeader.Descripcion}
//                               alignment={"center"}
//                               allowEditing={false}
//                               cellRender={(data) => {
//                                 if (
//                                   data.value === null ||
//                                   data.value === "" ||
//                                   Array.isArray(data.value) === false ||
//                                   data.value.length === 0
//                                 ) {
//                                   return "";
//                                 } else
//                                   return (
//                                     <div>
//                                       {data.value.map((item, index) => (
//                                         <div key={index}>
//                                           <a
//                                             href={item.path}
//                                             target="_blank"
//                                             rel="noopener noreferrer"
//                                           >
//                                             <Tooltip
//                                               title={item.name}
//                                               aria-label="Ver adjunto"
//                                             >
//                                               <VisibilityIcon />
//                                             </Tooltip>
//                                           </a>
//                                         </div>
//                                       ))}
//                                     </div>
//                                   );
//                               }}
//                             />
//                           );
//                         } else {
//                           if (childHeader.TipoCampo === 20) {
//                             return (
//                               <Column
//                                 key={childHeader.Nombre}
//                                 dataField={childHeader.Nombre}
//                                 caption={childHeader.Descripcion}
//                                 alignment={"center"}
//                                 allowEditing={false}
//                                 cellRender={(data) => {
//                                   return (
//                                     <VisibilityIcon
//                                       onClick={() =>
//                                         handleGridModalClick(data.value)
//                                       }
//                                     />
//                                   );
//                                 }}
//                               />
//                             );
//                           } else {
//                             return (
//                               <Column
//                                 key={childHeader.Nombre}
//                                 dataField={childHeader.Nombre}
//                                 caption={childHeader.Descripcion}
//                                 alignment={"center"}
//                                 allowEditing={false}
//                               />
//                             );
//                           }
//                         }
//                       })}
//                     </Column>
//                   );
//                 } else {
//                   if (header.TipoCampo === 20) {
//                     return (
//                       <Column
//                         key={header.Nombre}
//                         dataField={header.Nombre}
//                         caption={header.Descripcion}
//                         alignment={"center"}
//                         allowEditing={false}
//                         cellRender={(data) => {
//                           return (
//                             <VisibilityIcon
//                               onClick={() =>
//                                 handleGridModalClick({
//                                   ...data.value,
//                                   Nombre: header.Descripcion,
//                                   Items: data,
//                                 })
//                               }
//                             />
//                           );
//                         }}
//                       />
//                     );
//                   } else {
//                     return (
//                       <Column
//                         key={header.Nombre}
//                         dataField={header.Nombre}
//                         caption={header.Descripcion}
//                         //dataType={header.TipoCampo === 7 ? 'boolean' : ''}
//                         alignment={"center"}
//                         allowEditing={false}
//                         cellRender={(data) => {
//                           if (
//                             header.TipoCampo === 8 ||
//                             header.TipoCampo === 6 ||
//                             header.TipoCampo === 18
//                           ) {
//                             //adjuntos
//                             return (
//                               <a
//                                 href="#"
//                                 onClick={() =>
//                                   newTabAuth({
//                                     fileName: data.value,
//                                     key: LLAVE_ADJUNTOS_AVISO,
//                                   })
//                                 }
//                               >
//                                 {data.value !== "" && data.value !== null ? (
//                                   <Icon
//                                     component={VisibilityIcon}
//                                     style={{ color: "#53718f" }}
//                                   />
//                                 ) : (
//                                   ""
//                                 )}
//                               </a>
//                             );
//                           } else if (header.TipoCampo === 7) {
//                             return data.value === "1" ? "Sí" : "No";
//                           } else if (header.Nombre == "CodigoAviso") {
//                             return (
//                               <a
//                                 href={`/gestion/${data.value}`}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                               >
//                                 {data.value}
//                               </a>
//                             );
//                           } else {
//                             return data.value;
//                           }
//                         }}
//                       />
//                     );
//                   }
//                 }
//               })}
//               <ColumnChooser
//                 enabled={true}
//                 allowSearch={true}
//                 mode={"select"}
//                 emptyPanelText="Buscar columna"
//                 title="Ocultar:"
//               />
//               <Summary>
//                 <GroupItem
//                   column="CodigoAviso"
//                   summaryType="count"
//                   displayFormat={"Total: {0}"}
//                 />
//                 {/* <GroupItem
//                                     column="Tiempo Transcurrido"
//                                     summaryType={'sum'}
//                                     showInGroupFooter={true}
//                                 /> */}
//                 {summaryColumns.map((summaryColumn) => (
//                   <GroupItem
//                     key={summaryColumn.column}
//                     column={summaryColumn.column}
//                     summaryType={summaryColumn.summaryType}
//                     showInGroupFooter={summaryColumn.showInGroupFooter}
//                   />
//                 ))}
//               </Summary>
//               <MasterDetail
//                 enabled={tieneFormularioAsistencia}
//                 component={(row) => {
//                   console.log(row);
//                   return (
//                     //TODO: Pasar a un componente
//                     <DataGrid
//                       dataSource={row.data.data.FormularioDetalle}
//                       showBorders={true}
//                       rowAlternationEnabled
//                     >
//                       {headersDinamicosAsistencia.map((header) => (
//                         <Column
//                           key={header.Nombre}
//                           dataField={header.Nombre}
//                           caption={header.Descripcion}
//                           dataType={
//                             header.TipoDato === "undefined"
//                               ? undefined
//                               : header.TipoDato
//                           }
//                           format={
//                             header.TipoDato === "date"
//                               ? "yyyy/MM/dd"
//                               : undefined
//                           }
//                           alignment={"center"}
//                           allowEditing={header.Nombre === "Dpi" ? true : false}
//                           cellRender={(data) => {
//                             if (header.TipoCampo === 8) {
//                               return (
//                                 <a
//                                   href={data.value}
//                                   target="_blank"
//                                   rel="noopener noreferrer"
//                                 >
//                                   {data.value !== "" && data.value !== null ? (
//                                     <Icon
//                                       component={VisibilityIcon}
//                                       style={{ color: "#53718f" }}
//                                     />
//                                   ) : (
//                                     ""
//                                   )}
//                                 </a>
//                               );
//                             } else if (header.Nombre === "Foto") {
//                               return cellRender(data);
//                             } else {
//                               return data.value;
//                             }
//                           }}
//                         />
//                       ))}
//                     </DataGrid>
//                   );
//                 }}
//               />

//               <Export
//                 enabled={true}
//                 fileName="Avisos"
//                 allowExportSelectedData={false}
//                 texts={{ exportAll: "Exportar a Excel" }}
//               />
//             </DataGrid>
//             <div style={{ display: "flex", justifyContent: "end" }}>
//               {/* <Tooltip title="Reiniciar tabla" aria-label="Reiniciar tabla">
//                                 <IconButton
//                                     onClick={resetDataGrid}
//                                     style={{ color: "#69bd4b", marginBottom: "20px" }}
//                                 >
//                                     <RefreshIcon />
//                                 </IconButton>
//                             </Tooltip> */}
//             </div>
//           </Box>
//           <br />
//           <br />
//           <br />
//           <Modal
//             open={modalGridVisible}
//             onClose={() => setModalGridVisible(false)}
//             className={classes.modal}
//           >
//             <div className={classes.modalDiv}>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} sm={12} md={12} lg={12}>
//                   <Typography variant="h6" style={{ textAlign: "center" }}>
//                     {datosGrid.Nombre}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={12} lg={12}>
//                   <MaterialTable
//                     title=""
//                     columns={datosGrid.Cols}
//                     data={datosGrid.Filas}
//                   />
//                 </Grid>
//               </Grid>
//             </div>
//           </Modal>

//           <Divider style={{ margin: "20px 0" }} />
//           <Typography
//             variant="h4"
//             style={{ textAlign: "center", margin: "20px 0" }}
//           >
//             Configuración
//           </Typography>
//           <Modal
//             open={modalVisible}
//             onClose={handleModalCancel}
//             className={classes.modal}
//           >
//             <div className={classes.modalDiv}>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} sm={12}>
//                   <FormControl
//                     variant="outlined"
//                     fullWidth
//                     className={classes.formControl}
//                   >
//                     <InputLabel id="summary-type-label">
//                       Tipo de Subtotal
//                     </InputLabel>
//                     <Select
//                       value={summaryType}
//                       onChange={handleSummaryTypeChange}
//                       labelId="summary-type-label"
//                       label="Summary Type"
//                     >
//                       <MenuItem value="count">Count - Conteo de filas</MenuItem>
//                       <MenuItem value="min">Min - Valor mínimo</MenuItem>
//                       <MenuItem value="max">Max - Valor máximo</MenuItem>
//                       <MenuItem value="sum">
//                         Sum - Sumatoria de la columna
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={12}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={showInGroupFooter}
//                         onChange={handleShowInGroupFooterChange}
//                         name="checkedB"
//                         color="primary"
//                       />
//                     }
//                     label="Mostrar en el pie de grupo"
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddSummary}
//                   >
//                     Añadir resumen/subtotal
//                   </Button>
//                 </Grid>
//               </Grid>
//             </div>
//           </Modal>
//           <Box className={classes.box}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="h6" style={{ textAlign: "center" }}>
//                   Resumen/Subtotal
//                 </Typography>
//                 <Typography variant="body1" style={{ textAlign: "center" }}>
//                   Seleccione una columna para agregar un resumen/subtotal al
//                   agrupar los datos
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   variant="outlined"
//                   className={classes.formControl}
//                 >
//                   <InputLabel id="column-select-label">
//                     Seleccionar Columna
//                   </InputLabel>
//                   <Select
//                     autoWidth
//                     labelId="column-select-label"
//                     value={selectedColumn}
//                     onChange={handleColumnSelect}
//                     label="Seleccionar Columna"
//                   >
//                     <MenuItem value="">
//                       <em>Ninguna</em>
//                     </MenuItem>
//                     {headersDinamicos.map((header) => (
//                       <MenuItem key={header.Nombre} value={header.Nombre}>
//                         {header.Descripcion}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <Typography variant="h6" style={{ textAlign: "center" }}>
//                   Subtotales:{" "}
//                 </Typography>
//                 <Grid container spacing={3}>
//                   {summaryColumns.map((summaryColumn) => (
//                     <Grid item xs={6} sm={12} key={summaryColumn.column}>
//                       <Grid container spacing={3}>
//                         <Grid item xs={6} sm={9}>
//                           <Grid container spacing={3}>
//                             <Grid item xs={12} sm={4}>
//                               <b>Columna: </b>
//                               <span style={{ overflowWrap: "break-word" }}>
//                                 {summaryColumn.column}
//                               </span>
//                             </Grid>
//                             <Grid item xs={12} sm={4}>
//                               <b>Tipo resumen: </b>
//                               <span>{summaryColumn.summaryType}</span>
//                             </Grid>
//                             <Grid item xs={12} sm={4}>
//                               <b>En footer: </b>
//                               <span>
//                                 {summaryColumn.showInGroupFooter ? "Sí" : "No"}
//                               </span>
//                             </Grid>
//                           </Grid>
//                         </Grid>
//                         <Grid item xs={6} sm={3}>
//                           <Button
//                             variant="outlined"
//                             color="secondary"
//                             onClick={() =>
//                               handleRemoveSummary(summaryColumn.column)
//                             }
//                           >
//                             Remover
//                           </Button>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Box>
//           {selectedFoto && (
//             <Dialog open={true} onClose={handleFotoDialogClose}>
//               <DialogContent style={{ display: "flex" }}>
//                 <img
//                   src={selectedFoto}
//                   alt="Empleado"
//                   style={{ maxHeight: "100%", objectFit: "contain" }}
//                 />
//               </DialogContent>
//             </Dialog>
//           )}
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// };

// const styles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textAlign: "center",
//   },
//   modalDiv: {
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//     borderRadius: "5px",
//   },
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     marginLeft: theme.spacing.unit,
//     marginRight: theme.spacing.unit,
//     width: "100%",
//   },
//   paperPerfil: {
//     position: "relative",
//     width: "100%", //theme.spacing.unit * 50,
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing.unit * 1,
//     outline: "none",
//   },
//   paper: {
//     position: "absolute",
//     width: theme.spacing.unit * 100,
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing.unit * 1,
//     outline: "none",
//   },
//   buttonAdd: {
//     margin: theme.spacing.unit,
//   },
//   small: {
//     width: theme.spacing(3),
//     height: theme.spacing(3),
//   },
//   large: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//   },
//   greenAvatar: {
//     fontSize: "2.5em",
//     color: "#fff",
//     // borderStyle:'solid',
//     // borderWidth:'1 !important',
//     // borderColor:'#3f51b5 !important',
//     backgroundColor: green[500],
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//   },
//   paperCard: {
//     padding: 20,
//     width: "100%",
//   },
//   box: {
//     position: "relative",
//     width: "100%", //theme.spacing.unit * 50,
//     padding: theme.spacing.unit * 1,
//     outline: "none",
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
//   secondNavigationModal: {
//     position: "absolute",
//     width: "50%",
//     height: "400px",
//     maxHeight: "400px",
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing.unit * 2,
//     outline: "none",
//   },
// }));

// export default ReporteUniversalGrid;

////////////////////////////////////////////////////////////////

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";
import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  LoadPanel,
  MasterDetail,
  OperationDescriptions,
  Paging,
  Scrolling,
  SearchPanel,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import ExcelJS from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

const LLAVE_ADJUNTOS_AVISO = "IMAGE_REPOSITORY_AVISOS";

const ReporteUniversalGrid = ({
  FechaInicio,
  FechaFin,
  TipoGestion,
  IncluirTareas,
}) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [headersDinamicos, setHeadersDinamicos] = useState([]);
  const [headersDinamicosAsistencia, setHeadersDinamicosAsistencia] = useState(
    []
  );
  const [tieneFormularioAsistencia, setTieneFormularioAsistencia] =
    useState(false);
  const [avisos, setAvisos] = useState([]);
  const [summaryColumns, setSummaryColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [summaryType, setSummaryType] = useState("");
  const [showInGroupFooter, setShowInGroupFooter] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFoto, setSelectedFoto] = useState("");
  const [modalGridVisible, setModalGridVisible] = useState(false);
  const [datosGrid, setDatosGrid] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      console.log("cargando");
      setLoading(true);
      let uri = `api/reporteUniversal/getAvisosDeTipo?inicio=${FechaInicio}&fin=${FechaFin}&tipoGestion=${TipoGestion}&incluirTareas=${IncluirTareas}`;
      const request = await API.fetchGetRequest(uri);
      if (request.data.response) {
        setAvisos(request.data.data.Avisos);
        setHeadersDinamicos(request.data.data.CamposEncabezado);
        setTieneFormularioAsistencia(
          request.data.data.TieneFormularioAsistencia
        );
        setHeadersDinamicosAsistencia(
          request.data.data.CamposEncabezadoAsistencia
        );
        console.log(request.data.data.TieneFormularioAsistencia);
      } else {
        console.log(request.data.mensaje);
        toast.error("Ocurrió un error al cargar los datos.");
      }
      setLoading(false);
      console.log(request.data.data);
    };
    try {
      fetchData();
    } catch (error) {
      console.log(error);
      toast.error("Ocurrió un error al cargar los datos.");
    }
  }, [FechaInicio, FechaFin, TipoGestion]);

  useEffect(() => {
    const storedSummaryColumns = localStorage.getItem(
      `${TipoGestion}summaryColumns`
    );
    if (storedSummaryColumns) {
      setSummaryColumns(JSON.parse(storedSummaryColumns));
    }
  }, []);

  //Para descargar los adjuntos, se necesita acceder a través de la api y con token
  async function newTabAuth({ fileName, key }) {
    const session = await isSignedIn();
    const baseApi = await API.baseApi();

    axios({
      url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  const handleAddSummary = () => {
    const newSummaryColums = [
      ...summaryColumns,
      { column: selectedColumn, summaryType, showInGroupFooter },
    ];
    setSummaryColumns(newSummaryColums);
    localStorage.setItem(
      `${TipoGestion}summaryColumns`,
      JSON.stringify(newSummaryColums)
    );
    setModalVisible(false);
  };

  const handleRemoveSummary = (column) => {
    const newSummaryColums = summaryColumns.filter(
      (summaryColumn) => summaryColumn.column !== column
    );
    setSummaryColumns(newSummaryColums);
    localStorage.setItem(
      `${TipoGestion}summaryColumns`,
      JSON.stringify(newSummaryColums)
    );
  };

  const handleSummaryTypeChange = (target) => {
    setSummaryType(target.target.value);
  };

  const handleShowInGroupFooterChange = (e) => {
    setShowInGroupFooter(e.target.checked);
  };

  const handleColumnSelect = (target) => {
    //Verificar si la columna seleccionada ya tiene un resumen
    const existe = summaryColumns.find(
      (summaryColumn) => summaryColumn.column === target.target.value
    );
    if (existe) {
      toast.error("La columna seleccionada ya tiene un resumen.");
      return;
    }
    setSelectedColumn(target.target.value);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setSelectedColumn(null);
    setSummaryType("count");
    setShowInGroupFooter(false);
    setModalVisible(false);
  };

  const dataGrid = useRef(null);

  const resetDataGrid = () => {
    dataGrid.current.instance.state(null);
  };

  const cellRender = (data) => {
    const foto = data.value;
    if (!foto || foto === "") {
      return <div style={{ width: "50px" }}>-</div>;
    }
    return (
      <div onClick={() => handleFotoClick(foto)} style={{ cursor: "pointer" }}>
        <img src={foto} alt="Empleado" style={{ width: "50px" }} />
      </div>
    );
  };

  const handleFotoClick = (foto) => {
    setSelectedFoto(foto);
  };

  const handleFotoDialogClose = () => {
    setSelectedFoto(null);
  };

  const handleGridModalClick = (row) => {
    console.log(row);
    let filas = [...row.Filas];
    //Obtener columnas tipo hora
    const horas = row.Items.filter((x) => x.tipoCampo === 14);
    const titulos = horas.forEach((campoHora, i) => {
      console.log("label", campoHora.title);
      console.log("hora pura", campoHora);

      //reemplaza valor de campos de fecha de cada fila
      filas.forEach((fila, i) => {
        if (
          fila[campoHora.title] !== null &&
          fila[campoHora.title].hasOwnProperty("horas") &&
          fila[campoHora.title].hasOwnProperty("minutos")
        ) {
          fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
            fila[campoHora.title].minutos
          }`;
        } else {
          // fila[campoHora.title] = null
        }
      });
    });
    const data = {
      Filas: filas,
      Cols: row.Items,
      Nombre: row.Nombre,
    };
    console.log("Datos de grid", data);
    setDatosGrid(data);
    setModalGridVisible(true);
  };

  const handleGridModalClickParent = (row, title, dataa) => {
    console.log(dataa);
    let filas = [...row.Filas];
    //Obtener columnas tipo hora
    const horas = row.Items.filter((x) => x.tipoCampo === 14);
    const titulos = horas.forEach((campoHora, i) => {
      console.log("label", campoHora.title);
      console.log("hora pura", campoHora);

      //reemplaza valor de campos de fecha de cada fila
      filas.forEach((fila, i) => {
        if (
          fila[campoHora.title] !== null &&
          fila[campoHora.title].hasOwnProperty("horas") &&
          fila[campoHora.title].hasOwnProperty("minutos")
        ) {
          fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
            fila[campoHora.title].minutos
          }`;
        } else {
          // fila[campoHora.title] = null
        }
      });
    });
    const data = {
      Filas: filas,
      Cols: row.Items,
      Nombre: row.Nombre,
    };
    console.log("Datos de grid", data);
    setDatosGrid(data);
    setModalGridVisible(true);
  };

  const getGridDataFromRow = (row) => {
    console.log(row);
    let filas = [...row.Filas];
    //Obtener columnas tipo hora
    const horas = row.Items.filter((x) => x.tipoCampo === 14);
    const titulos = horas.forEach((campoHora, i) => {
      console.log("label", campoHora.title);
      console.log("hora pura", campoHora);

      //reemplaza valor de campos de fecha de cada fila
      filas.forEach((fila, i) => {
        if (
          fila[campoHora.title] !== null &&
          fila[campoHora.title].hasOwnProperty("horas") &&
          fila[campoHora.title].hasOwnProperty("minutos")
        ) {
          fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
            fila[campoHora.title].minutos
          }`;
        } else {
          // fila[campoHora.title] = null
        }
      });
    });
    const data = {
      Filas: filas,
      Cols: row.Items,
      Nombre: row.Nombre,
    };
    console.log("Datos de grid", data);

    return data;
  };

  // const onExporting = (e) => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Datos");

  //   // Define the main headers for your grid (adjust as needed)
  //   const mainHeaders = e.component
  //     .getVisibleColumns()
  //     .map((col) => col.caption);

  //   // Insert the headers as the first row in the worksheet
  //   worksheet.addRow(mainHeaders).eachCell((cell, colNumber) => {
  //     cell.font = { bold: true };
  //     cell.border = {
  //       top: { style: "thin" },
  //       left: { style: "thin" },
  //       bottom: { style: "thin" },
  //       right: { style: "thin" },
  //     };
  //     cell.alignment = {
  //       wrapText: true,
  //       vertical: "middle",
  //       horizontal: "center",
  //     };

  //     // Set a wider column width
  //     worksheet.getColumn(colNumber).width = 20;
  //   });

  //   // Determine the range for the auto filter
  //   const totalColumns = mainHeaders.length;
  //   const lastColumnLetter = worksheet.getColumn(totalColumns).letter;

  //   // Add auto filter to the header row
  //   worksheet.autoFilter = {
  //     from: "A1",
  //     to: `${lastColumnLetter}1`, // Calculate the correct last column letter dynamically
  //   };

  //   let currentRowIndex = 2; // Start after the header

  //   // Iterate over each main row in the grid
  //   e.component.getVisibleRows().forEach((row) => {
  //     const rowData = row.data;

  //     // Insert the main row into the worksheet
  //     const mainRowData = e.component
  //       .getVisibleColumns()
  //       .map((col) => rowData[col.dataField]);
  //     const masterRow = worksheet.addRow(mainRowData);
  //     masterRow.outlineLevel = 0; // No indent for the main row

  //     // Move to the next row after inserting the main row
  //     currentRowIndex++;

  //     // Iterate over headersDinamicos to insert child tables for this main row
  //     headersDinamicos.forEach((headerGroup) => {
  //       if (Array.isArray(headerGroup.HeadersHijos)) {
  //         headerGroup.HeadersHijos.forEach((header) => {
  //           if (header.TipoCampo === 20 && rowData[header.Nombre]) {
  //             const childData = getGridDataFromRow(rowData[header.Nombre]);

  //             // Insert child headers
  //             const childHeaders = [
  //               "",
  //               ...childData.Cols.map((col) => col.title),
  //             ]; // Indented to start from column B
  //             const newHeaderRow = worksheet.addRow(childHeaders);
  //             newHeaderRow.outlineLevel = 1; // Indent the child headers
  //             newHeaderRow.eachCell((cell, colNumber) => {
  //               if (colNumber > 1) {
  //                 cell.font = { bold: true };
  //                 cell.fill = {
  //                   type: "pattern",
  //                   pattern: "solid",
  //                   fgColor: { argb: "FFCCCCCC" },
  //                 };
  //                 cell.border = {
  //                   top: { style: "thin" },
  //                   left: { style: "thin" },
  //                   bottom: { style: "thin" },
  //                   right: { style: "thin" },
  //                 };
  //               }
  //             });

  //             currentRowIndex++;

  //             // Insert child rows
  //             childData.Filas.forEach((childRow) => {
  //               const childRowData = [
  //                 "",
  //                 ...childData.Cols.map((col) => childRow[col.title]),
  //               ]; // Indented to start from column B
  //               const newRow = worksheet.addRow(childRowData);
  //               newRow.outlineLevel = 1; // Indent the child rows
  //               newRow.eachCell((cell, colNumber) => {
  //                 if (colNumber > 1) {
  //                   cell.border = {
  //                     top: { style: "thin" },
  //                     left: { style: "thin" },
  //                     bottom: { style: "thin" },
  //                     right: { style: "thin" },
  //                   };
  //                 }
  //               });

  //               currentRowIndex++;
  //             });

  //             // Insert an empty row for separation
  //             const newRow = worksheet.addRow([""]);
  //             newRow.outlineLevel = 1; // Indent the empty row

  //             currentRowIndex++;
  //           }
  //         });
  //       }
  //     });
  //   });

  //   worksheet.properties.outlineProperties = {
  //     summaryBelow: false,
  //     summaryRight: false,
  //   };

  //   //get formatted date for the file name in format DD-MM-YY
  //   const date = new Date();
  //   const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;

  //   // Write the workbook to a buffer and save it
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     saveAs(
  //       new Blob([buffer], { type: "application/octet-stream" }),
  //       `Datos ${formattedDate} - ${TipoGestion}.xlsx`
  //     );
  //   });

  //   e.cancel = true; // Prevent default export behavior
  // };

  // Para exportar toda la data a Excel
  const onExporting = async (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Datos");

    // Usar directamente la data cargada en avisos
    const allData = avisos;

    // Define encabezados
    const headers = e.component.getVisibleColumns().map((col) => col.caption);

    worksheet.addRow(headers).eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      worksheet.getColumn(colNumber).width = 20;
    });

    // Insertar filas de datos
    allData.forEach((rowData) => {
      const row = e.component
        .getVisibleColumns()
        .map((col) => rowData[col.dataField] || "");
      worksheet.addRow(row);
    });

    // Agregar filtros automáticos
    worksheet.autoFilter = {
      from: "A1",
      to: `${String.fromCharCode(64 + headers.length)}1`,
    };

    // Guardar el archivo con un nombre dinámico
    const date = new Date();
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const fileName = `Datos ${formattedDate} - ${TipoGestion}.xlsx`;

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName
      );
    });

    e.cancel = true; // Evitar el comportamiento por defecto
  };

  const agregarBotonesExtras = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: resetDataGrid,
          hint: "Reiniciar filtros y agrupaciones",
        },
      },
      {
        widget: "dxButton",
        options: {
          icon: "exportxlsx",
          hint: "Exportar con subgrupos",
          onClick: () => {
            e.component.exportToExcel(false);
          },
        },
        location: "after",
      }
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress style={{ marginBottom: "20px" }} />
          <br />
          <br />
        </div>
      ) : (
        <React.Fragment>
          <Box className={classes.box}>
            <DataGrid
              dataSource={avisos}
              showBorders={true}
              ref={dataGrid}
              columnAutoWidth={true}
              wordWrapEnabled={true}
              showRowLines={true}
              rowAlternationEnabled={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              onToolbarPreparing={agregarBotonesExtras}
              onExporting={onExporting}
              height={"80vh"}
              allowColumnReordering={true}
              groupPanel={{
                visible: true,
                emptyPanelText:
                  "Arrastre un encabezado aquí para agrupar por esa columna",
              }}
            >
              <LoadPanel
                height={100}
                width={250}
                indicatorSrc="https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
              />

              <Grouping autoExpandAll={false} contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <Scrolling
                mode="virtual"
                rowRenderingMode="virtual"
                scrollByThumb={true}
              />
              <Paging enabled={false} />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={
                  `${TipoGestion}DataGrid` + (IncluirTareas ? "ConTareas" : "")
                }
              />
              <FilterRow visible={true}>
                <OperationDescriptions
                  between="Entre"
                  contains="Contiene"
                  endsWith="Termina con"
                  equal="Igual"
                  greaterThan="Mayor que"
                  greaterThanOrEqual="Mayor o igual que"
                  lessThan="Menor que"
                  lessThanOrEqual="Menor o igual que"
                  notContains="No contiene"
                  notEqual="No igual"
                  startsWith="Empieza con"
                />
              </FilterRow>
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={240} placeholder="Buscar..." />

              {headersDinamicos.map((header) => {
                if (header.EsHeaderPadre) {
                  return (
                    <Column
                      key={header.Nombre}
                      //dataField={header.Nombre}
                      caption={header.Descripcion}
                      alignment={"center"}
                      allowEditing={false}
                      headerCellRender={(headerData) => {
                        return (
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.2em",
                              color: "black",
                            }}
                          >
                            {headerData.column.caption}
                          </div>
                        );
                      }}
                    >
                      {header.HeadersHijos.map((childHeader) => {
                        if (childHeader.Nombre.includes("Adjuntos")) {
                          return (
                            <Column
                              key={childHeader.Nombre}
                              dataField={childHeader.Nombre}
                              caption={childHeader.Descripcion}
                              alignment={"center"}
                              allowEditing={false}
                              cellRender={(data) => {
                                if (
                                  data.value === null ||
                                  data.value === "" ||
                                  Array.isArray(data.value) === false ||
                                  data.value.length === 0
                                ) {
                                  return "";
                                } else
                                  return (
                                    <div>
                                      {data.value.map((item, index) => (
                                        <div key={index}>
                                          <a
                                            href={item.path}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <Tooltip
                                              title={item.name}
                                              aria-label="Ver adjunto"
                                            >
                                              <VisibilityIcon />
                                            </Tooltip>
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  );
                              }}
                            />
                          );
                        } else {
                          if (childHeader.TipoCampo === 20) {
                            return (
                              <Column
                                key={childHeader.Nombre}
                                dataField={childHeader.Nombre}
                                caption={childHeader.Descripcion}
                                alignment={"center"}
                                allowEditing={false}
                                cellRender={(data) => {
                                  return (
                                    <VisibilityIcon
                                      onClick={() =>
                                        handleGridModalClick(data.value)
                                      }
                                    />
                                  );
                                }}
                              />
                            );
                          } else {
                            return (
                              <Column
                                key={childHeader.Nombre}
                                dataField={childHeader.Nombre}
                                caption={childHeader.Descripcion}
                                alignment={"center"}
                                allowEditing={false}
                              />
                            );
                          }
                        }
                      })}
                    </Column>
                  );
                } else {
                  if (header.TipoCampo === 20) {
                    return (
                      <Column
                        key={header.Nombre}
                        dataField={header.Nombre}
                        caption={header.Descripcion}
                        alignment={"center"}
                        allowEditing={false}
                        cellRender={(data) => {
                          return (
                            <VisibilityIcon
                              onClick={() =>
                                handleGridModalClick({
                                  ...data.value,
                                  Nombre: header.Descripcion,
                                  Items: data,
                                })
                              }
                            />
                          );
                        }}
                      />
                    );
                  } else {
                    return (
                      <Column
                        key={header.Nombre}
                        dataField={header.Nombre}
                        caption={header.Descripcion}
                        //dataType={header.TipoCampo === 7 ? 'boolean' : ''}
                        alignment={"center"}
                        allowEditing={false}
                        cellRender={(data) => {
                          if (
                            header.TipoCampo === 8 ||
                            header.TipoCampo === 6 ||
                            header.TipoCampo === 18
                          ) {
                            //adjuntos
                            return (
                              <a
                                href="#"
                                onClick={() =>
                                  newTabAuth({
                                    fileName: data.value,
                                    key: LLAVE_ADJUNTOS_AVISO,
                                  })
                                }
                              >
                                {data.value !== "" && data.value !== null ? (
                                  <Icon
                                    component={VisibilityIcon}
                                    style={{ color: "#53718f" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </a>
                            );
                          } else if (header.TipoCampo === 7) {
                            return data.value === "1" ? "Sí" : "No";
                          } else if (header.Nombre == "CodigoAviso") {
                            return (
                              <a
                                href={`/gestion/${data.value}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.value}
                              </a>
                            );
                          } else {
                            return data.value;
                          }
                        }}
                      />
                    );
                  }
                }
              })}
              <ColumnChooser
                enabled={true}
                allowSearch={true}
                mode={"select"}
                emptyPanelText="Buscar columna"
                title="Ocultar:"
              />
              <Summary>
                <GroupItem
                  column="CodigoAviso"
                  summaryType="count"
                  displayFormat={"Total: {0}"}
                />
                {/* <GroupItem
                                    column="Tiempo Transcurrido"
                                    summaryType={'sum'}
                                    showInGroupFooter={true}
                                /> */}
                {summaryColumns.map((summaryColumn) => (
                  <GroupItem
                    key={summaryColumn.column}
                    column={summaryColumn.column}
                    summaryType={summaryColumn.summaryType}
                    showInGroupFooter={summaryColumn.showInGroupFooter}
                  />
                ))}
              </Summary>
              <MasterDetail
                enabled={tieneFormularioAsistencia}
                component={(row) => {
                  console.log(row);
                  return (
                    //TODO: Pasar a un componente
                    <DataGrid
                      dataSource={row.data.data.FormularioDetalle}
                      showBorders={true}
                      rowAlternationEnabled
                    >
                      {headersDinamicosAsistencia.map((header) => (
                        <Column
                          key={header.Nombre}
                          dataField={header.Nombre}
                          caption={header.Descripcion}
                          dataType={
                            header.TipoDato === "undefined"
                              ? undefined
                              : header.TipoDato
                          }
                          format={
                            header.TipoDato === "date"
                              ? "yyyy/MM/dd"
                              : undefined
                          }
                          alignment={"center"}
                          allowEditing={header.Nombre === "Dpi" ? true : false}
                          cellRender={(data) => {
                            if (header.TipoCampo === 8) {
                              return (
                                <a
                                  href={data.value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {data.value !== "" && data.value !== null ? (
                                    <Icon
                                      component={VisibilityIcon}
                                      style={{ color: "#53718f" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </a>
                              );
                            } else if (header.Nombre === "Foto") {
                              return cellRender(data);
                            } else {
                              return data.value;
                            }
                          }}
                        />
                      ))}
                    </DataGrid>
                  );
                }}
              />

              <Export
                enabled={true}
                fileName="Avisos"
                allowExportSelectedData={false}
                texts={{ exportAll: "Exportar a Excel" }}
              />
            </DataGrid>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {/* <Tooltip title="Reiniciar tabla" aria-label="Reiniciar tabla">
                                <IconButton
                                    onClick={resetDataGrid}
                                    style={{ color: "#69bd4b", marginBottom: "20px" }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          </Box>
          <br />
          <br />
          <br />
          <Modal
            open={modalGridVisible}
            onClose={() => setModalGridVisible(false)}
            className={classes.modal}
          >
            <div className={classes.modalDiv}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    {datosGrid.Nombre}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MaterialTable
                    title=""
                    columns={datosGrid.Cols}
                    data={datosGrid.Filas}
                  />
                </Grid>
              </Grid>
            </div>
          </Modal>

          <Divider style={{ margin: "20px 0" }} />
          <Typography
            variant="h4"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            Configuración
          </Typography>
          <Modal
            open={modalVisible}
            onClose={handleModalCancel}
            className={classes.modal}
          >
            <div className={classes.modalDiv}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="summary-type-label">
                      Tipo de Subtotal
                    </InputLabel>
                    <Select
                      value={summaryType}
                      onChange={handleSummaryTypeChange}
                      labelId="summary-type-label"
                      label="Summary Type"
                    >
                      <MenuItem value="count">Count - Conteo de filas</MenuItem>
                      <MenuItem value="min">Min - Valor mínimo</MenuItem>
                      <MenuItem value="max">Max - Valor máximo</MenuItem>
                      <MenuItem value="sum">
                        Sum - Sumatoria de la columna
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showInGroupFooter}
                        onChange={handleShowInGroupFooterChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Mostrar en el pie de grupo"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddSummary}
                  >
                    Añadir resumen/subtotal
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          <Box className={classes.box}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  Resumen/Subtotal
                </Typography>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Seleccione una columna para agregar un resumen/subtotal al
                  agrupar los datos
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="column-select-label">
                    Seleccionar Columna
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="column-select-label"
                    value={selectedColumn}
                    onChange={handleColumnSelect}
                    label="Seleccionar Columna"
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {headersDinamicos.map((header) => (
                      <MenuItem key={header.Nombre} value={header.Nombre}>
                        {header.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  Subtotales:{" "}
                </Typography>
                <Grid container spacing={3}>
                  {summaryColumns.map((summaryColumn) => (
                    <Grid item xs={6} sm={12} key={summaryColumn.column}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <b>Columna: </b>
                              <span style={{ overflowWrap: "break-word" }}>
                                {summaryColumn.column}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <b>Tipo resumen: </b>
                              <span>{summaryColumn.summaryType}</span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <b>En footer: </b>
                              <span>
                                {summaryColumn.showInGroupFooter ? "Sí" : "No"}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              handleRemoveSummary(summaryColumn.column)
                            }
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {selectedFoto && (
            <Dialog open={true} onClose={handleFotoDialogClose}>
              <DialogContent style={{ display: "flex" }}>
                <img
                  src={selectedFoto}
                  alt="Empleado"
                  style={{ maxHeight: "100%", objectFit: "contain" }}
                />
              </DialogContent>
            </Dialog>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  modalDiv: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  box: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

export default ReporteUniversalGrid;
