import {
  AddAlert,
  AlarmAdd,
  Apps,
  Assessment,
  Assignment,
  AssignmentTurnedIn,
  AvTimer,
  Build,
  Computer,
  ContactMail,
  Dashboard,
  DeviceHub,
  DirectionsRun,
  Event,
  EventNote,
  FormatColorFill,
  GridOn,
  Group,
  GroupWork,
  GroupWorkOutlined,
  ListAlt,
  LocalHospital,
  LocalHospitalOutlined,
  LocalLibrary,
  LocalOffer,
  Map,
  MonetizationOn,
  Notifications,
  Pageview,
  Person,
  PersonAdd,
  Room,
  School,
  Security,
  Settings,
  SupervisedUserCircle,
  TableChart,
  Timeline,
  VpnKey,
  WorkOutline,
} from "@material-ui/icons";
import React from "react";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import HotelIcon from "@material-ui/icons/Hotel";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";

//import WorkspacesIcon from '@mui/icons-material/Workspaces';

export const items = [
  {
    id: 0,
    icon: <Apps style={{ color: "white" }} />,
    label: "Home",
    type: "single",
    to: "/home",
    permiso: "Menu.Principal.Web.Gestiones",
  },
  {
    id: 1,
    icon: <Assessment style={{ color: "white" }} />,
    label: "Reportes",
    type: "primarysub",
    to: "/reportes",
    permiso: "Menu.Principal.Web.Reportes",
    subitems: [
      {
        id: 0,
        icon: <Timeline style={{ color: "white" }} />,
        label: "Kanban Gestiones",
        type: "secondarysub",
        to: "/kanban",
        permiso: "Menu.Principal.Web.Kanban",
      },
      {
        id: 1,
        icon: <AssignmentTurnedIn style={{ color: "white" }} />,
        label: "Obra Civil",
        type: "secondarysub",
        to: "/reporteObraCivil",
        permiso: "Menu.Principal.Web.ReporteObraCivil",
      },
      {
        id: 2,
        icon: <AssignmentTurnedIn style={{ color: "white" }} />,
        label: "Inocuidad",
        type: "secondarysub",
        to: "/reporteInocuidad",
        permiso: "Menu.Principal.Web.ReporteInocuidad",
      },
      {
        id: 3,
        icon: <GridOn style={{ color: "white" }} />,
        label: "Reporte Universal",
        type: "secondarysub",
        to: "/reporteUniversal",
        permiso: "Menu.Principal.Web.ReporteUniversal",
      },
      {
        id: 5,
        icon: <AssignmentTurnedIn style={{ color: "white" }} />,
        label: "Asistencias",
        type: "secondaryPrimarysub",
        to: "/reportesCAT",
        permiso: "Menu.Principal.Web.Reportes",
        subitems: [
          {
            id: 500,
            icon: <AssignmentTurnedIn style={{ color: "white" }} />,
            label: "Asistencias",
            type: "tertiarysub",
            to: "/reporteAsistencias",
            permiso: "Menu.Principal.Web.ReporteAsistencias",
          },
          {
            id: 501,
            icon: <AssignmentTurnedIn style={{ color: "white" }} />,
            label: "Inconsistencias asistencia",
            type: "tertiarysub",
            to: "/reporte/inconsistenciaAsistencia/",
            //permiso: "Menu.Principal.Web.ReporteAsistencias",
            permiso: "Menu.Principal.Web.ReporteInconsistenciaAsistencias",
          },
          {
            id: 502,
            icon: <AssignmentTurnedIn style={{ color: "white" }} />,
            label: "Cosecha",
            type: "tertiarysub",
            to: "/reporte/asistenciasCosecha/",
            //permiso: "Menu.Principal.Web.ReporteAsistencias",
            permiso: "Menu.Principal.Web.ReporteInconsistenciaAsistencias",
          },
          {
            id: 503,
            icon: <AssignmentTurnedIn style={{ color: "white" }} />,
            label: "CAT",
            type: "tertiarysub",
            to: "/reporte/asistenciasCat/",
            //permiso: "Menu.Principal.Web.ReporteAsistencias",
            permiso: "Menu.Principal.Web.ReporteAsistenciasCat",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    icon: <Security style={{ color: "white" }} />,
    label: "Seguridad",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <Person style={{ color: "white" }} />,
        label: "Usuarios",
        type: "secondarysub",
        to: "/usuarios",
        permiso: "Menu.Principal.Web.Usuarios",
      },
      {
        id: 1,
        icon: <PersonAdd style={{ color: "white" }} />,
        label: "Roles",
        type: "secondarysub",
        to: "/roles",
        permiso: "Menu.Principal.Web.Roles",
      },
      {
        id: 3,
        icon: <VpnKey style={{ color: "white" }} />,
        label: "Permisos",
        type: "secondarysub",
        to: "/permisos",
        permiso: "Menu.Principal.Web.Permisos",
      },
      {
        id: 4,
        icon: <Group style={{ color: "white" }} />,
        label: "Grupos",
        type: "secondarysub",
        to: "/grupo",
        permiso: "Menu.Principal.Web.Grupos",
      },
      {
        id: 5,
        icon: <GroupWork style={{ color: "white" }} />,
        label: "Agrupaciones",
        type: "secondarysub",
        to: "/agrupaciones",
        permiso: "Menu.Principal.Web.Agrupaciones",
      },
    ],
  },
  {
    id: 3,
    icon: <Settings style={{ color: "white" }} />,
    label: "Configuración",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <DeviceHub style={{ color: "white" }} />,
        label: "Flujos",
        type: "secondarysub",
        to: "/flujo",
        permiso: "Menu.Principal.Web.Flujos",
      },
      {
        id: 1,
        icon: <LocalOffer style={{ color: "white" }} />,
        label: "Etiquetas",
        type: "secondarysub",
        to: "/familiaetiqueta",
        permiso: "Menu.Principal.Web.Etiquetas",
      },
      {
        id: 2,
        icon: <ListAlt style={{ color: "white" }} />,
        label: "Formularios",
        type: "secondarysub",
        to: "/formdesigner",
        permiso: "Menu.Principal.Web.formDesigner",
      },
      {
        id: 3,
        icon: <AlarmAdd style={{ color: "white" }} />,
        label: "Plantillas Notificaciones",
        type: "secondarysub",
        to: "/plantillasnotificaciones/",
        permiso: "Menu.Principal.Web.PlantillaNotificaciones",
      },
      {
        id: 4,
        icon: <Map style={{ color: "white" }} />,
        label: "Landmarks",
        type: "secondarysub",
        to: "/landmarksAdmin",
        permiso: "Menu.Principal.Web.LandmarksAdmin",
      },
    ],
  },
  {
    id: 4,
    icon: <LocalHospitalOutlined style={{ color: "white" }} />,
    label: "Clínica ILU",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <LocalHospital style={{ color: "white" }} />,
        label: "Consultas Médicas",
        type: "secondarysub",
        to: "/superfiltro/",
        permiso: "Menu.Principal.Web.Clinica",
        esClinica: true,
      },
      {
        id: 1,
        icon: <Assignment style={{ color: "white" }} />,
        label: "Ficha Clínica",
        type: "secondarysub",
        to: "/ficha-clinica",
        permiso: "Menu.Principal.Web.FichaClinica",
      },
      {
        id: 2,
        icon: <TableChart style={{ color: "white" }} />,
        label: "Reporte Vacunación",
        type: "secondarysub",
        to: "/reporte-vacunacion",
        permiso: "Menu.Principal.Web.FichaClinica",
      },
      {
        id: 2,
        icon: <TableChart style={{ color: "white" }} />,
        label: "Citas",
        type: "secondarysub",
        to: "/citas-programadas",
        permiso: "Menu.Principal.Web.FichaClinica",
      },
    ],
  },
  {
    id: 5,
    icon: <EventNote style={{ color: "white" }} />,
    label: "Comunicaciones",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <AddAlert style={{ color: "white" }} />,
        label: "Administrar Notific.",
        type: "secondarysub",
        to: "/notificaciones",
        permiso: "Menu.Principal.Web.Notificaciones",
      },
      // {
      //   id: 1,
      //   icon: <Event style={{ color: "white" }} />,
      //   label: "Eventos",
      //   type: "secondarysub",
      //   to: "/eventos-calendario",
      //   permiso: "Menu.Principal.Web.Notificaciones",
      // },
      {
        id: 2,
        icon: <FormatColorFill style={{ color: "white" }} />,
        label: "Temas Notific.",
        type: "secondarysub",
        to: "/centro-temas-notificaciones",
        permiso: "Menu.Principal.Web.Notificaciones",
      },
    ],
  },
  {
    id: 6,
    icon: <Room style={{ color: "white" }} />,
    label: "Contact tracing",
    type: "single",
    to: "/contacttracing",
    permiso: "Menu.Principal.Web.ContactTracing",
  },
  {
    id: 7,
    icon: <Notifications style={{ color: "white" }} />,
    label: "Notificaciones",
    type: "single",
    to: "/notificacionesPorUsuario",
    permiso: "Menu.Principal.Web.ContactTracing",
  },
  {
    id: 8,
    icon: <SupervisedUserCircle style={{ color: "white" }} />,
    label: "GESPILU",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <Dashboard style={{ color: "white" }} />,
        label: "Tablero",
        type: "secondarysub",
        to: "/dashboard-gespilu",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 1,
        icon: <GroupWorkOutlined style={{ color: "white" }} />,
        label: "Organización",
        type: "secondarysub",
        to: "/organizacion",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 2,
        icon: <Pageview style={{ color: "white" }} />,
        label: "Empleados",
        type: "secondarysub",
        to: "/buscar-empleados",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 3,
        icon: <WorkOutline style={{ color: "white" }} />,
        label: "Puesto Laboral",
        type: "secondarysub",
        to: "/puesto-laboral",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 4,
        icon: <DirectionsRun style={{ color: "white" }} />,
        label: "Competencias",
        type: "secondarysub",
        to: "/lista-competencias",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 5,
        icon: <ListAlt style={{ color: "white" }} />,
        label: "Verbos de Nivel",
        type: "secondarysub",
        to: "/verbos",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 6,
        icon: <LocalOffer style={{ color: "white" }} />,
        label: "Etiquetas Skill",
        type: "secondarysub",
        to: "/etiquetaskill",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },

      // se traslado al menú de configuración de GESPILU
      // {
      //   id: 7,
      //   icon: <DateRange style={{ color: "white" }} />,
      //   label: "Temporada",
      //   type: "secondarysub",
      //   to: "/temporada",
      //   permiso: "Menu.Principal.Web.PuestoLaboral",
      // },
      {
        id: 8,
        icon: <TableChart style={{ color: "white" }} />,
        label: "Reporte Movimientos",
        type: "secondarysub",
        to: "/reporte-movimientos-personal",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 9,
        icon: <TableChart style={{ color: "white" }} />,
        label: "Reporte Plazas Laborales",
        type: "secondarysub",
        to: "/reporte-plazas-laborales",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 10,
        icon: <AvTimer style={{ color: "white" }} />,
        label: "Tipo de Turno",
        type: "secondarysub",
        to: "/tipo-turno",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 11,
        icon: <School style={{ color: "white" }} />,
        label: "Carga Educación",
        type: "secondarysub",
        to: "/carga-educacion",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 12,
        icon: <LocalLibrary style={{ color: "white" }} />,
        label: "Carga Creditos Internos",
        type: "secondarysub",
        to: "/carga-creditos",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 13,
        icon: <Settings style={{ color: "white" }} />,
        label: "Configuración",
        type: "secondarysub",
        to: "/configuracion-gespilu",
        permiso: "Menu.Principal.Web.PuestoLaboral",
      },
      {
        id: 14,
        icon: <MonetizationOn style={{ color: "white" }} />,
        label: "Escala Salarial",
        type: "secondarysub",
        to: "/escala-salarial",
        permiso: "Menu.Principal.Web.EscalaSalarial",
      },
    ],
  },
  {
    id: 9,
    icon: <ContactMail style={{ color: "white" }} />,
    label: "Gestión de Correo",
    type: "single",
    to: "/correo-compensaciones",
    permiso: "Menu.Principal.Web.CorreoElectronico",
  },
  {
    id: 10,
    icon: <HotelIcon style={{ color: "white" }} />,
    label: "Hotel",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <AssignmentTurnedInIcon style={{ color: "white" }} />,
        label: "Reservas",
        type: "secondarysub",
        to: "/hotel/reserva",
        permiso: "Menu.Principal.Web.Reservaciones",
      },
      {
        id: 1,
        icon: <MeetingRoomIcon style={{ color: "white" }} />,
        label: "Habitaciones",
        type: "secondarysub",
        to: "/hotel/habitaciones",
        permiso: "Menu.Principal.Web.Reservaciones",
      },
    ],
  },
  {
    id: 12,
    icon: <Build style={{ color: "white" }} />,
    label: "Mant. Preventivo",
    type: "primarysub",
    subitems: [
      {
        id: 0,
        icon: <WorkOutline style={{ color: "white" }} />,
        label: "Carga Masiva",
        type: "secondarysub",
        to: "/mantenimiento-preventivo",
        permiso: "Menu.Principal.Web.MantenimientoPreventivo",
      },
      {
        id: 1,
        icon: <Computer style={{ color: "white" }} />,
        label: "Equipos",
        type: "secondarysub",
        to: "/equipo-mantenimiento",
        permiso: "Menu.Principal.Web.MantenimientoPreventivo",
      },
      {
        id: 3,
        icon: <Computer style={{ color: "white" }} />,
        label: "Consulta",
        type: "secondarysub",
        to: "/superfiltro/",
        permiso: "Menu.Principal.Web.MantenimientoPreventivo",
        esMantenimientoPreventivo: true,
      },
    ],
  },
];
