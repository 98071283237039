import { Card, CardContent, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

import FormHelperText from "@material-ui/core/FormHelperText";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import MenuItem from "@material-ui/core/MenuItem";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import { convertYMD } from "../../utils/helpers";
//Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";
import AsistenciasGrid from "../../Asistencias/Components/asistenciasGrid";
import MapaLeaflet from "../../Asistencias/Components/mapaLeaflet";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import TagList from "../../avisos-objects/components/tag-list";
import UsuarioInfo from "../../helpers/components/usuario-info";
import DetalleAnalisisSalarial from "../../organizacion/components/detalle-analisis-salarial-componente";
import DetalleMovimientosPersonal from "../../organizacion/components/detalle-movimientos-componente";
import DetalleRequerimientoPersonalComponente from "../../organizacion/components/detalle-requerimiento-personal-componente";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";
import UserInfo from "../../usuario-info/containers/user-info";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import EncabezadoAvance from "./encabezado-avance";
import GestionOpciones from "./gestion-opciones";
import { set } from "date-fns";
import UsuariosResumen from "./usuarios-resumen";
import UsuariosResumenEditable from "./usuarios-resumen-editable";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rootTabla: {
    maxWidth: 1024,
    marginTop: theme.spacing.unit * 1,
    // width: "100%"
    // overflowX: "auto",
  },
  table: {
    width: "100%",
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  chip: {
    marginLeft: 10,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  inputTitulo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColor: {
    color: "#2196F3",
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function GestionGeneral(props) {
  const classes = useStyles();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [aviso, setAviso] = useState(null);
  const [vacaciones, setVacaciones] = useState(null);
  const [idAvisoVacaciones, setIdAvisoVacaciones] = useState();
  const [permisoDescargarPDF, setpermisoDescargarPDF] = useState(false);
  const [detallePermisoUsuarioActual, setDetallePermisoUsuarioActual] =
    useState(null);
  const [tituloAviso, setTituloAviso] = useState("");
  const [descripcionAviso, setDescripcionAviso] = useState("");
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [codigoPlantillaNotificaciones, setcodigoPlantillaNotificaciones] =
    useState(null);

  const [listaPlantillaNotificaciones, setlistaPlantillaNotificaciones] =
    useState([]);

  const [codigoAviso, setCodigoAviso] = useState("");
  const nombreProblema = ((aviso || {}).Problema || {}).Nombre;
  const nombreSolicitante = ((aviso || {}).Solicitante || {})
    .NombreCompletoUsuario;
  const shortName = ((aviso || {}).Solicitante || {}).ShortAlias;
  const ImagenPerfil = ((aviso || {}).Solicitante || {}).ImagenPerfil;
  const userNameSolicitante = ((aviso || {}).Solicitante || {}).UsuarioName;
  const codigoUsuarioSolicitante = ((aviso || {}).Solicitante || {})
    .CodigoUsuario;
  const fechaCreacionAmigable = (aviso || {}).FechaInsertoAmigable;
  const [textoBusquedaEtiqueta, setTextoBusquedaEtiqueta] = useState("");
  const [textoBusquedaUsuario, setTextoBusquedaUsuario] = useState("");
  const [causasDisponiblesCopia, setCausasDisponiblesCopia] = useState([]);
  const [usuariosDisponiblesCopia, setUsuariosDisponiblesCopia] = useState([]);
  const [openModalEtiquetas, setOpenModalEtiquetas] = useState(false);
  const [openModalUsuarios, setOpenModalUsuarios] = useState(false);
  const [listaFamiliasEtiquetas, setListaFamiliasEtiquetas] = useState([]);
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [objFamilia, setObjFamilia] = useState({
    CodigoFamiliaEtiqueta: 0,
    Nombre: "Todas",
    Descripcion: "",
    Activo: true,
  });
  const [
    codigoFamiliaEtiquetaSeleccionada,
    setCodigoFamiliaEtiquetaSeleccionada,
  ] = useState(0);

  const [objPermiso, setObjPermiso] = useState({
    CodigoAvisoUsuarioPermiso: 0,
    Nombre: "Ninguno",
  });

  const [objPlantillAlerta, setObjPlantillaAlerta] = useState({
    CodigoPlantillaAlerta: 0,
    Nombre: "Ninguna",
  });

  const [codigoPermisoSeleccionado, setCodigoPermisoSeleccionado] = useState(0);
  const [
    codigoPlantilaAlertaSeleccionada,
    setCodigoPlantillaAlertaSeleccionada,
  ] = useState(0);

  const [listaPermisoAvisoUsuario, setListaPermisoAvisoUsuario] = useState([]);
  const [listaPlantillaAlerta, setListaPlantillaAlerta] = useState([]);
  const [currentUserEdit, setCurrentUserEdit] = useState(null);
  const [currentUserEditName, setCurrentUserEditName] = useState("");
  const [codigoPlantillaNotificacionesRef, setRef] = useState("");
  const [openModalUsuarioConfig, setOpenModalUsuarioConfig] = useState(false);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [procesandoPDFVacaciones, setProcesandoPDFVacaciones] = useState(false);

  const handleObjPermiso = (newInputValue) => {
    setObjPermiso(newInputValue);
  };

  const handleCurrentUserEdit = (newInputValue) => {
    setCurrentUserEdit(newInputValue);
  };

  const handleRemoverUsuario = (codigoUsuario) => {
    props.removerUsuario(codigoUsuario);
  };
  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        const containerWidth =
          containerRef.current.getBoundingClientRect().width;
        setContainerWidth(containerWidth);
      }
    }, 2000);
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const containerWidth =
          containerRef.current.getBoundingClientRect().width;
        setContainerWidth(containerWidth);
      }
    };

    window.addEventListener("resize", updateWidth);

    // Limpieza del event listener
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    getPermissionList();
    getPlantillaAlertaList();
  }, []);

  useEffect(() => {
    if (props.aviso !== null) {
      setAviso(props.aviso);
      setTituloAviso(props.aviso.Titulo);
      setCodigoAviso(props.aviso.CodigoAviso);
      setFechaVencimiento(
        props.aviso.FechaVencimiento == null
          ? null
          : convertYMD(props.aviso.FechaVencimiento, "-")
      );
      setcodigoPlantillaNotificaciones(
        props.aviso.CodigoPlantillaNotificaciones == null
          ? 0
          : props.aviso.CodigoPlantillaNotificaciones
      );
    }
  }, [props.aviso]);

  useEffect(() => {
    setVacaciones(props.vacaciones);
  }, [props.vacaciones]);

  useEffect(() => {
    setlistaPlantillaNotificaciones(props.listaPlantillaNotificaciones);
  }, [props.listaPlantillaNotificaciones]);

  useEffect(() => {
    setIdAvisoVacaciones(props.idAvisoVacaciones);
  }, [props.idAvisoVacaciones]);

  useEffect(() => {
    setpermisoDescargarPDF(props.permisoDescargarPDF);
  }, [props.permisoDescargarPDF]);

  useEffect(() => {
    if (props.detallePermisoUsuarioActual !== null) {
      setDetallePermisoUsuarioActual(props.detallePermisoUsuarioActual);
    }
  }, [props.detallePermisoUsuarioActual]);

  useEffect(() => {
    // if (textoBusquedaEtiqueta.length > 0) {
    let filtro = props.causasDisponibles.filter((fila) => {
      return (
        fila.name.toLowerCase().includes(textoBusquedaEtiqueta.toLowerCase()) &&
        (codigoFamiliaEtiquetaSeleccionada === 0
          ? true
          : fila.familycode === codigoFamiliaEtiquetaSeleccionada)
      );
    });
    setCausasDisponiblesCopia(filtro);
    //} else {
    // setCausasDisponiblesCopia(props.causasDisponibles);
    //}
  }, [textoBusquedaEtiqueta, codigoFamiliaEtiquetaSeleccionada]);

  const busquedaEtiqueta = async () => {
    if (textoBusquedaEtiqueta.length > 0) {
      let filtro = props.causasDisponibles.filter((fila) => {
        return fila.name
          .toLowerCase()
          .includes(textoBusquedaEtiqueta.toLowerCase());
      });
      setCausasDisponiblesCopia(filtro);
    } else {
      setCausasDisponiblesCopia(props.causasDisponibles);
    }
  };

  const getPermissionList = async () => {
    const uri = `api/aviso/listarPermisoUsuario`;
    const request = await API.fetchGetRequest(uri);

    if (request.statusCode === 401) {
      return;
    } else {
      console.log("listaPermisos", request.data.data);
      setListaPermisoAvisoUsuario(request.data.data);
    }
  };

  const getPlantillaAlertaList = async () => {
    const uri = `api/aviso/listarPlantillaAlerta`;
    const request = await API.fetchGetRequest(uri);

    if (request.statusCode === 401) {
      return;
    } else {
      console.log("listaPlantillaAlerta", request.data.data);
      setListaPlantillaAlerta(request.data.data);
    }
  };

  const listarConfiguracionAvisoUsuario = async (codigoUsuario) => {
    const uri = `api/aviso/listarConfiguracionAvisoUsuario?CodigoAviso=${codigoAviso}&CodigoUsuario=${codigoUsuario}`;
    const request = await API.fetchGetRequest(uri);

    if (request.statusCode === 401) {
      return;
    } else {
      if (request.data.permiso === null) {
        console.log("PermisoUsuario", request.data.permiso);
        setCodigoPermisoSeleccionado(0);
        setObjPermiso({
          CodigoAvisoUsuarioPermiso: 0,
          Nombre: "Ninguno",
        });
      } else {
        console.log("PermisoUsuario", request.data.permiso);
        setCodigoPermisoSeleccionado(
          request.data.permiso.CodigoAvisoUsuarioPermiso
        );
        setObjPermiso(request.data.permiso);
      }

      if (request.data.plantillaAlerta === null) {
        setCodigoPlantillaAlertaSeleccionada(0);
        setObjPlantillaAlerta({
          CodigoPlantillaAlerta: 0,
          Nombre: "Ninguna",
        });
      } else {
        console.log("PlantillaAlerta", request.data.permiso);
        setCodigoPlantillaAlertaSeleccionada(
          request.data.plantillaAlerta.CodigoPlantillaAlerta
        );
        setObjPlantillaAlerta(request.data.plantillaAlerta);
      }
    }
  };

  const setPermissionUser = async (codigoPermisoSeleccionado) => {
    if (
      codigoPermisoSeleccionado === 0 ||
      codigoPermisoSeleccionado === null ||
      codigoPermisoSeleccionado === undefined
    ) {
      return;
    }
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    let data = {
      CodigoAviso: codigoAviso,
      CodigoUsuario: currentUserEdit,
      CodigoAvisoUsuarioPermiso: codigoPermisoSeleccionado,
      usuarioOpera: session.userName,
    };

    const request = await API.post(
      "/api/aviso/asignarPermisoAvisoUsuario",
      data
    );
    console.log("asignarPermisoAvisoUsuario", request);
    if (request.status === 401) {
      //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
      if (await signOut()) {
        window.location.url = "/Login";
      }
      return false;
    } else {
      toast.info("El permiso fue asignado con éxito");
      //setOpenModalUsuarioConfig(false);
    }
  };

  const setPlantillaAlertaUser = async (codigoPlantillaSeleccionada) => {
    if (
      codigoPlantillaSeleccionada === 0 ||
      codigoPlantillaSeleccionada === null ||
      codigoPlantillaSeleccionada === undefined
    ) {
      return;
    }
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    let data = {
      CodigoAviso: codigoAviso,
      CodigoUsuario: currentUserEdit,
      CodigoAvisoUsuarioPlantillaAlerta: codigoPlantillaSeleccionada,
      usuarioOpera: session.userName,
    };

    const request = await API.post(
      "/api/aviso/asignarPlantillaAlertaAvisoUsuario",
      data
    );
    console.log("asignarPlantillaAlertaAvisoUsuario", request);
    if (request.status === 401) {
      //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
      if (await signOut()) {
        window.location.url = "/Login";
      }
      return false;
    } else {
      toast.info("La frecuencia de notificaciones se cambió exitosamente");
      //setOpenModalUsuarioConfig(false);
    }
  };

  const cargarListaUsuarios = async () => {
    let crit = textoBusquedaUsuario;
    let criterio =
      crit === "" || crit === null || crit === undefined ? "x-x" : crit;

    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        setUsuariosDisponiblesCopia(request.data.usuarios);
      } else {
        setUsuariosDisponiblesCopia([]);
      }
    } catch (error) {
      console.log("cargarListaUsuarios error:" + error);
    }
  };

  const clickEliminarUsuario = () => {
    alert(" :D");
  };

  const setSearchEtiqueta = (name) => (event) => {
    setTextoBusquedaEtiqueta(event.target.value);
  };

  const setSearchUsuario = (name) => (event) => {
    setTextoBusquedaUsuario(event.target.value);
  };

  const seleccionarUsuario = (codigoUsuario) => {
    props.agregarUsuario(codigoUsuario);
    setOpenModalUsuarios(false);
  };

  const confirmarCancelacion = async () => {
    setConfirmOpen(true);
    setConfirmTexto(
      `¿Confirma que desea anular la gestión? esta acción no podrá revertirse.`
    );
  };

  const funcionOk = async () => {
    await props.cancelarAviso();
    setConfirmOpen(false);
  };

  const cerrarConfirm = () => {
    setConfirmOpen(false);
    setConfirmTexto("");
  };

  const descargarPDF = async (sinFirma) => {
    try {
      // console.log("inicio proceso de Vacaciones");
      setProcesandoPDFVacaciones(true);
      const session = await isSignedIn();
      const baseApi = API.baseApi();
      const response = await axios({
        url: `${baseApi}api/vacaciones/getPDF/${codigoAviso}/${sinFirma}`, //your url
        method: "GET",
        headers: {
          Authorization: "bearer " + session.accessToken,
        },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Vacaciones - " + codigoAviso + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      // console.error("Error al descargar el PDF", error);
      toast.error("Error al descargar el PDF: " + error);
    } finally {
      // console.log("finally proceso de Vacaciones");
      setProcesandoPDFVacaciones(false);
    }
  };

  return aviso != null ? (
    <div>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            {`Gestión # ${codigoAviso}`}
          </Typography>
          <Chip className={classes.chip} label={nombreProblema} />
          <div>
            <EncabezadoAvance percent={props.aviso.PorcentajeAvance} />
          </div>
        </div>
        {props.aviso.NumeroEstadosPosteriores > 0 &&
        (props.permisoEditar || detallePermisoUsuarioActual.Update) ? (
          <GestionOpciones confirmarCancelacion={confirmarCancelacion} />
        ) : (
          <></>
        )}
      </div>
      <Paper className={classes.paper}>
        <div className={classes.container} ref={containerRef}>
          <TextField
            className={classes.input}
            required
            label="Título"
            defaultValue={tituloAviso}
            onBlur={props.onChangeCampo("titulo")}
            disabled={
              props.aviso.NumeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Update)
                ? false
                : true
            }
            variant="outlined"
            fullWidth
          />
          {console.log(
            " props.dataPuestoLaboralSolicitud",
            props.dataPuestoLaboralSolicitud
          )}
          {props.aviso.Problema !== null &&
            aviso.CodigoProblema === props.idAvisoPuestoLaboral &&
            !props.dataPuestoLaboralSolicitud === null && (
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {props.dataPuestoLaboralSolicitud.TipoCambioPuestoLaboral}
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Nombre: {props.dataPuestoLaboralSolicitud.PuestoLaboral}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Nombre Corto : </b>
                    {props.dataPuestoLaboralSolicitud.NombreCorto}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Nivel : </b>
                    {props.dataPuestoLaboralSolicitud.NivelPuestoLaboral}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Puesto Genérico : </b>
                    {props.dataPuestoLaboralSolicitud.SubNivelPuestoLaboral}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Tipo de Puesto : </b>
                    {props.dataPuestoLaboralSolicitud.TipoPuestoLaboral}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Clasificación : </b>
                    {
                      props.dataPuestoLaboralSolicitud
                        .ClasificacionPuestoLaboral
                    }
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Grado : </b>
                    {props.dataPuestoLaboralSolicitud.Grado}
                  </Typography>
                </CardContent>
              </Card>
            )}
          {props.aviso.Problema !== null &&
            aviso.CodigoProblema === props.idAvisoSolicitudMovimientoPersonal &&
            props.dataSolicitudMovimientoPersonal !== null && (
              <DetalleMovimientosPersonal
                dataSolicitudMovimientoPersonal={
                  props.dataSolicitudMovimientoPersonal
                }
                aviso={props.aviso}
              />
            )}

          {props.aviso.Problema !== null &&
            aviso.CodigoProblema === props.idAvisoAnalisisSalarial &&
            props.dataAnalisisSalarial !== null && (
              <DetalleAnalisisSalarial
                dataAnalisisSalarial={props.dataAnalisisSalarial}
                aviso={props.aviso}
              />
            )}

          {props.aviso.Problema !== null &&
            aviso.CodigoProblema === props.idAvisoRequerimientoPersonal &&
            props.dataRequerimientoPersonal !== null && (
              <DetalleRequerimientoPersonalComponente
                dataSolicitudMovimientoPersonal={
                  props.dataRequerimientoPersonal
                }
                aviso={props.aviso}
              />
            )}
          {props.aviso.Problema !== null &&
            props.aviso.Problema.OcultarDescripcion !== true && (
              <TextField
                className={classes.input}
                required
                label="Descripción"
                defaultValue={props.aviso.Descripcion}
                onBlur={props.onChangeCampo("descripcion")}
                disabled={
                  props.aviso.NumeroEstadosPosteriores > 0 &&
                  (props.permisoEditar || detallePermisoUsuarioActual.Update)
                    ? false
                    : true
                }
                variant="outlined"
                multiline
                fullWidth
                rows={3}
              />
            )}

          {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
          {aviso.Problema.AplicaFechaVencimiento && (
            <TextField
              id="FechaVencimiento"
              label="Fecha vencimiento"
              type="date"
              defaultValue={fechaVencimiento}
              // value={this.state.fechaVencimiento}
              className={classes.textField}
              onBlur={props.onChangeCampo("fechaVencimiento")}
              disabled={
                props.aviso.NumeroEstadosPosteriores > 0 &&
                (props.permisoEditar || detallePermisoUsuarioActual.Update)
                  ? false
                  : true
              }
              variant="outlined"
              // className={classes.input}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}

          {/* Si es tipo de gestión de Asistencia, se muestra Grid y mapa de asistencia. El código es 650 en PRD */}
          {(aviso.Problema.TomaAsistenciaReconocimientoFacial ||
            aviso.Problema.TomaAsistenciaDPI ||
            aviso.Problema.TomaAsistenciaQR ||
            aviso.Problema.CodigoFormularioAsistencia > 0) && (
            <React.Fragment>
              <div className={classes.input}>
                <div className={classes.inputTitulo}>
                  <Typography variant="subtitle1">Asistentes</Typography>
                </div>
              </div>

              {containerWidth > 10 && (
                <div style={{ width: containerWidth }}>
                  <AsistenciasGrid
                    Aviso={props.aviso.CodigoAviso}
                    FechaInicio="01-01-2000"
                    FechaFin="01-01-2000"
                    TipoGestion={props.aviso.CodigoProblema}
                  />
                </div>
              )}

              <div className={classes.input}>
                <div className={classes.inputTitulo}>
                  <Typography variant="subtitle1">Ubicación</Typography>
                </div>
              </div>
              <MapaLeaflet aviso={props.aviso.CodigoAviso} />
            </React.Fragment>
          )}

          {props.aviso.Problema !== null &&
            props.aviso.Problema.OcultarPlantillaAlerta !== true && (
              <>
                <div className={classes.input}>
                  <div className={classes.inputTitulo}>
                    <Typography variant="subtitle1">
                      Plantilla frecuencia de alertas
                    </Typography>
                  </div>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%", marginTop: "17px" }}
                    >
                      <Select
                        label="Plantilla Frecuencia de alertas"
                        name="plantillaAlerta"
                        value={codigoPlantillaNotificaciones}
                        onChange={props.onChangeCampo(
                          "codigoPlantillaNotificaciones"
                        )}
                        disabled={
                          props.aviso.NumeroEstadosPosteriores > 0 &&
                          (props.permisoEditar ||
                            detallePermisoUsuarioActual.Update)
                            ? false
                            : true
                        }
                      >
                        {listaPlantillaNotificaciones.listaPlantillaNotificaciones.map(
                          (item) => (
                            <MenuItem
                              key={
                                "listaPlantillaAlerta" +
                                item.CodigoPlantillaAlerta
                              }
                              value={item.CodigoPlantillaAlerta}
                            >
                              {item.Nombre}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}

          {aviso.CodigoProblema === idAvisoVacaciones ? (
            <Grid>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Fechas de Vacaciones</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vacaciones.DetalleFechas.map((row) => (
                      <StyledTableRow key={row.Fecha}>
                        <StyledTableCell component="th" scope="row">
                          {new Date(row.Fecha).getDate() +
                            "/" +
                            (new Date(row.Fecha).getMonth() + 1) +
                            "/" +
                            new Date(row.Fecha).getFullYear()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Periodo</StyledTableCell>
                      <StyledTableCell>Otorgadas</StyledTableCell>
                      <StyledTableCell>Gozadas Anteriormente</StyledTableCell>
                      <StyledTableCell>Total Gozadas</StyledTableCell>
                      <StyledTableCell>Pendientes de Gozar</StyledTableCell>
                      <StyledTableCell>Solicitadas</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vacaciones.DetallePeriodos.map((row) => (
                      <StyledTableRow key={row.Fecha}>
                        <StyledTableCell component="th" scope="row">
                          {row.Periodo}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.Otorgadas}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.GozadasAnteriormente}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.TotalGozadas}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.PendientesGozar}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.Solicitadas}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {permisoDescargarPDF && (
                <Button
                  style={{
                    width: "100%",
                    // backgroundColor: "#69bd4b",
                    // color: "white",
                    backgroundColor: procesandoPDFVacaciones
                      ? "#4a5745"
                      : "#69bd4b", // Cambia el color de fondo condicionalmente
                    color: procesandoPDFVacaciones ? "black" : "white", // Cambia el color del texto condicionalmente
                    marginTop: "10px",
                  }}
                  className={classes.buttonAdd}
                  onClick={() => descargarPDF(true)}
                  disabled={procesandoPDFVacaciones}
                  startIcon={
                    procesandoPDFVacaciones && <CircularProgress size={20} />
                  }
                  // onClick={async () => {
                  //   const session = await isSignedIn();
                  //   const baseApi = await API.baseApi();
                  //   axios({
                  //     url: `${baseApi}api/vacaciones/getPDF/${codigoAviso}/${true}`, //your url
                  //     method: "GET",
                  //     headers: {
                  //       Authorization: "bearer " + session.accessToken,
                  //     },
                  //     responseType: "blob", // important
                  //   }).then((response) => {
                  //     const url = window.URL.createObjectURL(
                  //       new Blob([response.data])
                  //     );
                  //     const link = document.createElement("a");
                  //     link.href = url;
                  //     link.setAttribute(
                  //       "download",
                  //       "Vacaciones - " + codigoAviso + ".pdf"
                  //     ); //or any other extension
                  //     document.body.appendChild(link);
                  //     link.click();
                  //   });
                  // }}
                >
                  {procesandoPDFVacaciones
                    ? "Procesando..."
                    : "Descargar PDF Firmado"}
                </Button>
              )}

              <Button
                style={{
                  width: "100%",
                  // backgroundColor: "#69bd4b",
                  // color: "white",
                  backgroundColor: procesandoPDFVacaciones
                    ? "#4a5745"
                    : "#69bd4b", // Cambia el color de fondo condicionalmente
                  color: procesandoPDFVacaciones ? "black" : "white", // Cambia el color del texto condicionalmente
                  marginTop: "10px",
                }}
                className={classes.buttonAdd}
                onClick={() => descargarPDF(false)}
                disabled={procesandoPDFVacaciones}
                startIcon={
                  procesandoPDFVacaciones && <CircularProgress size={20} />
                }
                // onClick={async () => {
                //   const session = await isSignedIn();
                //   const baseApi = await API.baseApi();
                //   axios({
                //     url: `${baseApi}api/vacaciones/getPDF/${codigoAviso}/${false}`, //your url
                //     method: "GET",
                //     headers: {
                //       Authorization: "bearer " + session.accessToken,
                //     },
                //     responseType: "blob", // important
                //   }).then((response) => {
                //     const url = window.URL.createObjectURL(
                //       new Blob([response.data])
                //     );
                //     const link = document.createElement("a");
                //     link.href = url;
                //     link.setAttribute(
                //       "download",
                //       "Vacaciones - " + codigoAviso + ".pdf"
                //     ); //or any other extension
                //     document.body.appendChild(link);
                //     link.click();
                //   });
                // }}
              >
                {procesandoPDFVacaciones
                  ? "Procesando..."
                  : "Descargar PDF sin Firmar"}
              </Button>
            </Grid>
          ) : (
            console.log("Aviso no es vacaciones")
          )}
          {/* </Grid> */}

          <div className={classes.input}>
            <div className={classes.inputTitulo}>
              <Typography variant="subtitle1" gutterBottom>
                Usuarios Asignados
              </Typography>
              {props.aviso.NumeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Create) ? (
                <IconButton
                  aria-label="Nuevo usuario"
                  className={classes.margin}
                  onClick={() => {
                    setOpenModalUsuarios(true);
                  }}
                >
                  <PersonAddIcon
                    fontSize="small"
                    className={classes.iconColor}
                  />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
            {/* <div className={classes.classChips}> */}
            <UsuariosResumenEditable
              usuarios={props.usuarios}
              aviso={aviso}
              permisoEditar={props.permisoEditar}
              detallePermisoUsuarioActual={detallePermisoUsuarioActual}
              listarConfiguracionAvisoUsuario={listarConfiguracionAvisoUsuario}
              listaPermisoAvisoUsuario={listaPermisoAvisoUsuario}
              setPermissionUser={setPermissionUser}
              listaPlantillaAlerta={listaPlantillaAlerta}
              setPlantillaAlertaUser={setPlantillaAlertaUser}
              objPermisoProp={objPermiso}
              setObjPermiso={handleObjPermiso}
              objPlantillaAlertaProp={objPlantillAlerta}
              setCurrentUserEdit={handleCurrentUserEdit}
              removerUsuario={handleRemoverUsuario}
              recargar={() => props.recargar()}
            />
            {/* {props.usuarios.map((row, i) => (
                // <UserInfo userName={row[0].usuario}>

                <div
                  onClick={async () => {
                    if (
                      props.aviso.NumeroEstadosPosteriores > 0 &&
                      (props.permisoEditar ||
                        detallePermisoUsuarioActual.Update)
                    ) {
                      await listarConfiguracionAvisoUsuario(row[0].id);
                      setCurrentUserEdit(row[0].id);
                      setCurrentUserEditName(row[0].name);
                      setOpenModalUsuarioConfig(true);
                    } else {
                    }
                  }}
                >
                  <Chip
                    size="small"
                    avatar={<Avatar>{row[0].name.substring(0, 2)}</Avatar>}
                    label={row[0].name}
                    onDelete={() => {
                      if (
                        props.aviso.NumeroEstadosPosteriores > 0 &&
                        (props.permisoEditar ||
                          detallePermisoUsuarioActual.Delete)
                      ) {
                        props.removerUsuario(row[0].id);
                        props.recargar();
                      } else {
                        toast.warn(
                          "Oops... No tienes permiso para eliminar este usuario"
                        );
                        return;
                      }
                    }}
                  />
                </div>
                // </UserInfo>
              ))} */}
            {/* </div> */}
          </div>

          {props.aviso.Problema !== null &&
            props.aviso.Problema.OcultarEtiquetas !== true && (
              <div className={classes.input}>
                <div className={classes.inputTitulo}>
                  <Typography variant="subtitle1" gutterBottom>
                    Etiquetas
                  </Typography>

                  {props.aviso.NumeroEstadosPosteriores > 0 &&
                  (props.permisoEditar ||
                    detallePermisoUsuarioActual.Create) ? (
                    <IconButton
                      aria-label="Nueva etiqueta"
                      className={classes.margin}
                      onClick={() => {
                        setOpenModalEtiquetas(true);
                      }}
                    >
                      <AddIcon fontSize="small" className={classes.iconColor} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={classes.classChips}>
                  {props.causas.map((row, i) => (
                    <Chip
                      size="small"
                      label={row[0].name}
                      onDelete={() => {
                        if (
                          props.aviso.NumeroEstadosPosteriores > 0 &&
                          (props.permisoEditar ||
                            detallePermisoUsuarioActual.Delete)
                        ) {
                          props.removerCausas(row[0].id);
                          props.recargar();
                        } else {
                          toast.warn(
                            "Oops... No tienes permiso para eliminar esta etiqueta"
                          );
                          return;
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

          <div className={classes.input}>
            <div className={classes.inputTitulo}>
              <Typography variant="subtitle1" gutterBottom>
                Usuario afectado / solicitante
              </Typography>
            </div>
            <UserInfo userName={userNameSolicitante}>
              <div className={classes.classChips}>
                <UsuarioInfo
                  imagenPerfil={ImagenPerfil}
                  iniciales={shortName}
                  nombre={nombreSolicitante}
                  descripcion={`creo la gestión  ${fechaCreacionAmigable}`}
                />
              </div>
            </UserInfo>
          </div>
        </div>
      </Paper>

      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Asignar etiquetas"
        aria-describedby="Asignar etiquetas"
        open={openModalEtiquetas}
        onClose={() => {
          setOpenModalEtiquetas(false);
        }}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <PerfectScrollbar>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Agregar Etiquetas
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    className={classes.textField}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      marginTop: 25,
                    }}
                    options={props.familiaEtiqueta}
                    autoHighlight
                    getOptionLabel={(option) => option.Nombre}
                    disabled={false}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span></span>
                        {option.Nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Familia de etiquetas"
                        variant="outlined"
                      />
                    )}
                    value={objFamilia}
                    onChange={(event, newInputValue) => {
                      setObjFamilia(newInputValue ? newInputValue : -1);
                      setCodigoFamiliaEtiquetaSeleccionada(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoFamiliaEtiqueta
                            : 0
                        )
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtTextoBusquedaTag"
                    label="Tag"
                    className={classes.textField}
                    value={textoBusquedaEtiqueta}
                    onChange={setSearchEtiqueta("txtTextoBusquedaEtiqueta")}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} sm={12}>
                <Grid container spacing={1}>
                  <TagList
                    registros={causasDisponiblesCopia}
                    agregarCausas={props.agregarCausas}
                    closeModal={() => {
                      setOpenModalEtiquetas(false);
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
        </div>
      </Modal>

      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Asignar etiquetas"
        aria-describedby="Asignar etiquetas"
        open={openModalUsuarios}
        onClose={() => {
          setOpenModalUsuarios(false);
        }}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <PerfectScrollbar>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Agregar Usuarios
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtTextoBusquedaUsuario"
                    label="Usuario"
                    className={classes.textField}
                    value={textoBusquedaUsuario}
                    onChange={setSearchUsuario("txtTextoBusquedaUsuario")}
                    onKeyPress={(ev) => {
                      console.log("keyP: " + ev.key);
                      if (ev.key === "Enter") {
                        cargarListaUsuarios();
                        ev.preventDefault();
                      }
                    }}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#217ecf",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => cargarListaUsuarios()}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} sm={12}>
                <Grid container spacing={1}>
                  <UsuariosList
                    registros={usuariosDisponiblesCopia}
                    seleccionarUsuario={seleccionarUsuario}
                  />
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
        </div>
      </Modal>

      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Configuración de usuario"
        aria-describedby="Configuración de usuario"
        open={openModalUsuarioConfig}
        onClose={() => {
          setOpenModalUsuarioConfig(false);
        }}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <PerfectScrollbar style={{ height: "90%" }}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                {`Configuración de usuario ${currentUserEditName}`}
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    className={classes.textField}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      marginTop: 25,
                    }}
                    options={listaPermisoAvisoUsuario}
                    autoHighlight
                    getOptionLabel={(option) => option.Nombre}
                    disabled={false}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span></span>
                        {option.Nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Permiso sobre la gestión"
                        variant="outlined"
                      />
                    )}
                    value={objPermiso}
                    onChange={async (event, newInputValue) => {
                      setObjPermiso(newInputValue ? newInputValue : -1);
                      await setCodigoPermisoSeleccionado(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoAvisoUsuarioPermiso
                            : 0
                        )
                      );
                      setPermissionUser(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoAvisoUsuarioPermiso
                            : 0
                        )
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    className={classes.textField}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      marginTop: 25,
                    }}
                    options={listaPlantillaAlerta}
                    autoHighlight
                    getOptionLabel={(option) => option.Nombre}
                    disabled={false}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span></span>
                        {option.Nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Frecuencia de notificaciones"
                        variant="outlined"
                      />
                    )}
                    value={objPlantillAlerta}
                    onChange={async (event, newInputValue) => {
                      setObjPlantillaAlerta(newInputValue ? newInputValue : -1);
                      await setCodigoPlantillaAlertaSeleccionada(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoPlantillaAlerta
                            : 0
                        )
                      );
                      setPlantillaAlertaUser(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoPlantillaAlerta
                            : 0
                        )
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
          <div
            style={{
              display: "flex",
              paddingBottom: 25,
              textAlign: "right",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#69bd4b",
                  color: "white",
                }}
                className={classes.buttonAdd}
                onClick={() => setOpenModalUsuarioConfig(false)}
              >
                Cerrar
              </Button>
            </Grid>
          </div>
        </div>
      </Modal>

      <ConfirmDialog
        id="confirmar"
        keepMounted
        open={confirmOpen}
        onClose={cerrarConfirm}
        value=""
        texto={confirmTexto}
        okfunction={funcionOk}
      />
    </div>
  ) : (
    <React.Fragment>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            Gestión
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div>
          <CircularProgress />
        </div>
      </Paper>
    </React.Fragment>
  );
}
