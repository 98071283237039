import React, { useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AvatarGroup } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#2196F3",
    color: "white",
    fontSize: 10,
  },
  paperUserModal: {
    position: "absolute",
    // width: "75%",
    // height: "600px",
    // maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const UsuariosResumenEditable = ({
  usuarios,
  aviso,
  permisoEditar,
  detallePermisoUsuarioActual,
  listarConfiguracionAvisoUsuario,
  listaPermisoAvisoUsuario,
  setPermissionUser,
  listaPlantillaAlerta,
  setPlantillaAlertaUser,
  objPermisoProp,
  setObjPermiso,
  objPlantillaAlertaProp,
  setCurrentUserEdit,
  recargar,
  removerUsuario,
}) => {
  const classes = useStyles();
  // console.log("UsuariosResumenEditable", usuarios);

  const [open, setOpen] = useState(false);
  const [openModalUsuarioConfig, setOpenModalUsuarioConfig] = useState(false);

  const [currentUserEditName, setCurrentUserEditName] = useState("");
  const [objPlantillAlerta, setObjPlantillaAlerta] = useState(
    objPlantillaAlertaProp
  );
  const [
    codigoPlantilaAlertaSeleccionada,
    setCodigoPlantillaAlertaSeleccionada,
  ] = useState(0);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <HtmlTooltip
        interactive={false}
        leaveDelay={100}
        title={
          <React.Fragment>
            {usuarios.map((usuario, i) => (
              <Chip
                key={i}
                size="small"
                avatar={
                  <Avatar>
                    {usuario[0].name.substring(0, 1).toUpperCase()}
                  </Avatar>
                }
                label={
                  usuario[0].name.length > 19
                    ? usuario[0].name.substring(0, 19) + ".."
                    : usuario[0].name
                }
              />
            ))}
            <br />
            <strong>Click para ver más</strong>
          </React.Fragment>
        }
      >
        <div onClick={openModal}>
          <AvatarGroup classes={{ avatar: classes.smallAvatar }} max={4}>
            {usuarios.map((usuario, i) => (
              <Avatar key={i} className={classes.smallAvatar}>
                {usuario[0].name.substring(0, 1).toUpperCase()}
              </Avatar>
            ))}
          </AvatarGroup>
        </div>
      </HtmlTooltip> */}
      <Tooltip title={"Click para ver más"} placement="bottom-start">
        <div onClick={openModal} style={{ cursor: "pointer" }}>
          <AvatarGroup classes={{ avatar: classes.smallAvatar }} max={4}>
            {usuarios.map((usuario, i) => (
              <Avatar key={i} className={classes.smallAvatar}>
                {usuario[0].name.substring(0, 1).toUpperCase()}
              </Avatar>
            ))}
          </AvatarGroup>
        </div>
      </Tooltip>
      <Modal
        aria-labelledby="Listado de Usuarios"
        aria-describedby="Listado de Usuarios"
        open={open}
        onClose={closeModal}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <Typography variant="h6" gutterBottom>
            Usuarios Asignados
          </Typography>
          {usuarios.map((usuario, i) => (
            <div
              onClick={async () => {
                if (
                  aviso.NumeroEstadosPosteriores > 0 &&
                  (permisoEditar || detallePermisoUsuarioActual.Update)
                ) {
                  await listarConfiguracionAvisoUsuario(usuario[0].id);
                  setCurrentUserEdit(usuario[0].id);
                  setCurrentUserEditName(usuario[0].name);
                  closeModal();
                  setOpenModalUsuarioConfig(true);
                } else {
                }
              }}
            >
              <Chip
                key={i}
                size="small"
                avatar={
                  <Avatar>
                    {usuario[0].name.substring(0, 1).toUpperCase()}
                  </Avatar>
                }
                label={
                  usuario[0].name.length > 19
                    ? usuario[0].name.substring(0, 19) + ".."
                    : usuario[0].name
                }
                onDelete={() => {
                  if (
                    aviso.NumeroEstadosPosteriores > 0 &&
                    (permisoEditar || detallePermisoUsuarioActual.Delete)
                  ) {
                    removerUsuario(usuario[0].id);
                    recargar();
                  } else {
                    toast.warn(
                      "Oops... No tienes permiso para eliminar este usuario"
                    );
                    return;
                  }
                }}
              />
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Configuración de usuario"
        aria-describedby="Configuración de usuario"
        open={openModalUsuarioConfig}
        onClose={() => {
          setOpenModalUsuarioConfig(false);
        }}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <PerfectScrollbar style={{ height: "90%" }}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                {`Configuración de usuario ${currentUserEditName}`}
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    className={classes.textField}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      marginTop: 25,
                    }}
                    options={listaPermisoAvisoUsuario}
                    autoHighlight
                    getOptionLabel={(option) => option.Nombre}
                    disabled={false}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span></span>
                        {option.Nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Permiso sobre la gestión"
                        variant="outlined"
                      />
                    )}
                    value={objPermisoProp}
                    onChange={async (event, newInputValue) => {
                      setObjPermiso(newInputValue ? newInputValue : -1);

                      setPermissionUser(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoAvisoUsuarioPermiso
                            : 0
                        )
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    className={classes.textField}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      marginTop: 25,
                    }}
                    options={listaPlantillaAlerta}
                    autoHighlight
                    getOptionLabel={(option) => option.Nombre}
                    disabled={false}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span></span>
                        {option.Nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Frecuencia de notificaciones"
                        variant="outlined"
                      />
                    )}
                    value={objPlantillaAlertaProp}
                    onChange={async (event, newInputValue) => {
                      setObjPlantillaAlerta(newInputValue ? newInputValue : -1);
                      await setCodigoPlantillaAlertaSeleccionada(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoPlantillaAlerta
                            : 0
                        )
                      );
                      setPlantillaAlertaUser(
                        parseInt(
                          newInputValue
                            ? newInputValue.CodigoPlantillaAlerta
                            : 0
                        )
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
          <div
            style={{
              display: "flex",
              paddingBottom: 25,
              textAlign: "right",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#69bd4b",
                  color: "white",
                }}
                className={classes.buttonAdd}
                onClick={() => setOpenModalUsuarioConfig(false)}
              >
                Cerrar
              </Button>
            </Grid>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UsuariosResumenEditable;
