import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
} from "@material-ui/core";
import Layout from "../../layout/containers/Layout";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  PlayArrow,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
}));

const EmpleadoFamilia = (props) => {
  const { dataEmpleado } = props;
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [maestroTipoVacuna, setMaestroTipoVacuna] = useState([]);
  const [maestroDosis, setMaestroDosis] = useState([]);
  const [maestroPuestoVacunacion, setMaestroPuestoVacunacion] = useState([]);
  const [empleadoVacuna, setEmpleadoVacuna] = useState([]);
  const [codigoEmpleadoVacuna, setCodigoEmpleadoVacuna] = useState(0);
  const [nombreCompleto, setNombreCompleto] = useState("");

  const [codigoTipoVacuna, setCodigoTipoVacuna] = useState(0);
  const [codigoDosis, setCodigoDosis] = useState(0);
  const [codigoPuestoVacunacion, setCodigoPuestoVacunacion] = useState(0);
  const [numeroLote, setNumeroLote] = useState("");
  const [fechaVacunacion, setFechaVacunacion] = useState(new Date());

  const [valueAutoCompleteTipoVacuna, setValueAutoCompleteTipoVacuna] =
    useState(null);

  const [valueAutoCompleteDosis, setValueAutoCompleteDosis] = useState(null);

  const [
    valueAutoCompletePuestoVacunacion,
    setValueAutoCompletePuestoVacunacion,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      await cargarDatosEmpleado();
    };

    cargar();
  }, []);

  const cargarDatosEmpleado = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/FichaClinica/CitasProgramadas";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEmpleadoVacuna(request.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEmpleadoVacuna(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoEmpleadoVacuna(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoVacuna.filter(
      (elemento) => elemento.CodigoEmpleadoVacuna !== pId
    );

    console.log("Quitar el proyecto" + proyectosExcluidos);

    try {
      let uri = "api/Empleado/Vacuna/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarDatosEmpleado(props.dataEmpleado.DPI);
        setCodigoTipoVacuna(0);
        setCodigoDosis(0);
        setCodigoPuestoVacunacion(0);
        setValueAutoCompleteTipoVacuna(null);
        setNombreCompleto("");
        setNumeroLote("");
        setFechaVacunacion(new Date());
        setCodigoEmpleadoVacuna(0);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoVacuna.filter(
      (elemento) => elemento.CodigoEmpleadoVacuna === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      setCodigoEmpleadoVacuna(pId);
      setCodigoTipoVacuna(proyectosExcluidos[0].CodigoTipoVacuna);
      setCodigoDosis(proyectosExcluidos[0].CodigoDosis);
      setCodigoPuestoVacunacion(proyectosExcluidos[0].CodigoPuestoVacunacion);
      setNombreCompleto(proyectosExcluidos[0].NombreCompleto);
      setNumeroLote(proyectosExcluidos[0].NumeroLote);
      setFechaVacunacion(proyectosExcluidos[0].FechaVacunacion);

      const tiposParentesco = maestroTipoVacuna.filter(
        (elemento) =>
          elemento.CodigoTipoParentesco ===
          proyectosExcluidos[0].CodigoTipoParentesco
      );

      setValueAutoCompleteTipoVacuna(tiposParentesco[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip title="Iniciar" style={{ marginRight: 10, cursor: "pointer" }}>
          <PlayArrow
            style={{ color: "#337ab7", cursor: "pointer" }}
            // onClick={() =>
            //   handleAbrirDialogoEditar(rowInfo.data.CodigoEmpleadoVacuna)
            // }
            onClick={() =>
              window.open(
                "/clinica/fichaocupacional/" + rowInfo.data.dpi,
                "_blank"
              )
            }
          />
        </Tooltip>
      </>
    );
  };

  return (
    <Layout
      titulo="Citas Programadas"
      bodyContainerStyle={{ paddingBottom: "0" }}
      maxWidth={"lg"}
    >
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={empleadoVacuna}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="fechaSolicitada"
            caption="Fecha Solicitada"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="dpi" caption="DPI" />
          <Column dataField="nombreCompleto" caption="Nombre Completo" />
          <Column dataField="plaza" caption="Plaza" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </Layout>
  );
};

export default EmpleadoFamilia;
