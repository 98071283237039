import {
  AppBar,
  Avatar,
  Collapse,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import MenuGesDoc from "./MenuGesDoc";

import { useStoreActions } from "easy-peasy";
import logo from "../../image-repository/logo.png";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { permisoAgrupacion } from "../../utils/helpers";
import "../components/Layout.css";
import OptionComponent from "../components/option-component";
import OptionComponentCollapse from "../components/option-component-collapse";
import { items } from "./menu-items";

//idioma DevExpress
import { loadMessages, locale } from "devextreme/localization";
import esMessages from "devextreme/localization/messages/es.json";
import { PersonAddDisabled } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#202e3c",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background: "#2b3c4d",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerTitle: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingright: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500],
    borderStyle: "solid",
    borderWidth: "1 !important",
    borderColor: "white !important",
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  subMenuCollapse: {
    paddingLeft: theme.spacing(5),
  },
  drawerStyle: { backgroundColor: "#2b3c4d" },
  drawerOption: { color: "white" },
  menuCollapse: {
    paddingLeft: theme.spacing(3),
  },
}));

export default function Layout({ titulo, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  //idioma componentes devEpress
  loadMessages(esMessages);
  locale(navigator.language);
  const { getPermisosGesDoc } = useStoreActions((actions) => ({
    getPermisosGesDoc: actions.getPermisosGesDoc,
  }));
  const [open, setOpen] = React.useState(false);
  const [permisos, setPermisos] = useState([]);
  const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState("");
  const [inicialesUsuario, setInicialesUsuario] = useState("");
  const [codigoUsuario, setCodigoUsuario] = useState("");
  const [imagenPerfil, setImagenPerfil] = useState("");
  const llaveAgrupacion = "Menu.Principal.Web";
  const [codigoClinica, setCodigoClinica] = useState(419);
  const [codigoFichaOcupacional, setCodigoFichaOcupacional] = useState(419);
  const [codigoMantenimientoPreventivo, setCodigoMantenimientoPreventivo] =
    useState(304);
  const [foto, setFoto] = useState(null);
  const [userName, setUserName] = useState("");

  // const validarCalificacionPendiente = useCallback(async () => {
  //   let session = await isSignedIn();
  //   const calificacionResponse = await API.fetchGetRequest(
  //     `api/aviso/getobtenerCalificacion/${session.codigoUsuario}`
  //   );
  //   if (
  //     calificacionResponse.data.response &&
  //     calificacionResponse.data.asignados
  //   ) {
  //     history.push("/calificar");
  //   }
  // }, [history]);

  const validarCalificacionPendiente = useCallback(async () => {
    let session = await isSignedIn();
    const calificacionResponse = await API.fetchGetRequest(
      `api/aviso/getobtenerCalificacion/${session.codigoUsuario}`
    );

    console.log("calificacionResponse", calificacionResponse);
    if (
      calificacionResponse.data.response &&
      calificacionResponse.data.asignados
    ) {
      // Solo redirige a /calificar si el usuario está en /home o /calificar
      const currentPath = history.location.pathname;
      if (currentPath === "/home" || currentPath === "/calificar") {
        history.push("/calificar");
      }
    }
  }, [history]);

  useEffect(() => {
    validarCalificacionPendiente();
  }, [validarCalificacionPendiente]);

  useEffect(() => {
    async function obtenerListaAcceso() {
      let listaAcceso = await permisoAgrupacion(llaveAgrupacion);

      if (Array.isArray(listaAcceso)) {
        setPermisos(listaAcceso);
      }
    }

    async function obtenerDatosSesion() {
      let session = await isSignedIn();
      if (session.response) {
        getPermisosGesDoc();
        setUserName(session.userName);
        setCodigoUsuario(session.codigoUsuario);
        setImagenPerfil(session.imagenPerfil);
        setNombreCompletoUsuario(session.nombreCompletoUsuario);

        if (session.nombreCompletoUsuario !== null) {
          if (session.nombreCompletoUsuario.length > 0) {
            setInicialesUsuario(
              session.nombreCompletoUsuario.substring(0, 2).toUpperCase()
            );
          }
        }
        if (session.userName !== null) {
          cargarFoto(session.userName);
        }
        const requestCodGestionClinica = await API.fetchGetRequest(
          `api/fichaClinica/getCodigoProblemaClinica`
        );
        const verificarGestionClinica = requestCodGestionClinica.data.data;
        setCodigoClinica(verificarGestionClinica);

        const requestCodGestionFichaOcupacional = await API.fetchGetRequest(
          `api/fichaClinica/getCodigoProblemaFichaOcupacional`
        );
        const verificarGestionFichaOcupacional =
          requestCodGestionFichaOcupacional.data.data;
        setCodigoFichaOcupacional(verificarGestionFichaOcupacional);

        const requestCodGestionMantenimientoPreventivo =
          await API.fetchGetRequest(
            `api/MantenimientoPreventivo/getCodigoProblemaMantenimiento`
          );
        const verificarGestionMantenimientoPreventivo =
          requestCodGestionMantenimientoPreventivo.data.data;
        setCodigoMantenimientoPreventivo(
          verificarGestionMantenimientoPreventivo
        );
      }
    }

    obtenerDatosSesion();
    obtenerListaAcceso();

    // Add click event listener
    document.addEventListener("click", validarCalificacionPendiente);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("click", validarCalificacionPendiente);
    };
  }, [
    history,
    getPermisosGesDoc,
    llaveAgrupacion,
    validarCalificacionPendiente,
  ]);

  const cargarFoto = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [collapse, setCollapse] = useState(null);
  const [collapse2, setCollapse2] = useState(null);

  const toogleCollapse = (menuId) => {
    setCollapse(menuId === collapse ? null : menuId);
  };

  const toggleCollapse2 = (menuId) => {
    setCollapse2(menuId === collapse2 ? null : menuId);
  };

  const navigateTo = (path) => {
    history.push(path);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <a href="/">
              <img src={logo} className="ImgLogo" alt="logo" />
            </a>
          </Typography>
          {foto !== null ? (
            <Avatar
              alt={nombreCompletoUsuario}
              src={foto}
              className={classes.greenAvatar}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/perfil";
              }}
            />
          ) : imagenPerfil === null ||
            imagenPerfil === undefined ||
            imagenPerfil.endsWith("perfil/") ? (
            <Avatar
              alt=""
              className={classes.greenAvatar}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/perfil";
              }}
            >
              {inicialesUsuario}
            </Avatar>
          ) : (
            <Avatar
              alt={nombreCompletoUsuario}
              src={imagenPerfil}
              className={classes.greenAvatar}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/perfil";
              }}
            />
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
              toogleCollapse(null);
            }}
          >
            <ChevronLeftIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            {items.map((i) =>
              i.type === "single" ? (
                <OptionComponent
                  key={i.id}
                  name={i.label}
                  icon={i.icon}
                  url={i.to}
                  estadoBarra={open}
                  permiso={
                    permisos.filter((e) => e.Llave === i.permiso).length > 0
                  }
                  esSubmenu={false}
                />
              ) : i.type === "primarysub" ? (
                <div key={i.id}>
                  <OptionComponentCollapse
                    onClick={() => {
                      toogleCollapse(i.label);
                      handleDrawerOpen();
                    }}
                    label={i.label}
                    icon={i.icon}
                    collapse={collapse}
                    estadoBarra={open}
                  />
                  <Collapse
                    in={collapse === i.label}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {i.subitems.map((j) => {
                        if (
                          permisos.filter((e) => e.Llave === j.permiso).length >
                            0 &&
                          j.type === "secondarysub"
                        ) {
                          return (
                            <OptionComponent
                              key={j.id}
                              name={j.label}
                              icon={j.icon}
                              url={
                                j !== undefined
                                  ? j.esClinica === true
                                    ? "/superfiltro/" + codigoClinica
                                    : j.esMantenimientoPreventivo === true
                                    ? "/superfiltro/" +
                                      codigoMantenimientoPreventivo
                                    : j.to
                                  : j.to
                              }
                              estadoBarra={open}
                              permiso={
                                permisos.filter((e) => e.Llave === j.permiso)
                                  .length > 0
                              }
                              esSubmenu={true}
                            />
                          );
                        } else if (
                          permisos.filter((e) => e.Llave === j.permiso).length >
                            0 &&
                          j.type === "secondaryPrimarysub"
                        ) {
                          return (
                            <>
                              <OptionComponentCollapse
                                onClick={() => {
                                  toggleCollapse2(j.label);
                                }}
                                label={j.label}
                                icon={j.icon}
                                collapse={collapse2}
                                estadoBarra={open}
                                nivelSubmenu={2}
                              />
                              <Collapse
                                in={collapse2 === j.label}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {j.subitems.map((ka) => {
                                    if (
                                      permisos.filter(
                                        (e) => e.Llave === ka.permiso
                                      ).length > 0 &&
                                      ka.type === "tertiarysub"
                                    ) {
                                      return (
                                        <OptionComponent
                                          key={ka.id}
                                          name={ka.label}
                                          icon={ka.icon}
                                          url={ka.to}
                                          estadoBarra={open}
                                          permiso={
                                            permisos.filter(
                                              (e) => e.Llave === ka.permiso
                                            ).length > 0
                                          }
                                          esSubmenu={true}
                                          nivelSubmenu={3}
                                        />
                                      );
                                    }
                                  })}
                                </List>
                              </Collapse>
                            </>
                          );
                        }
                      })}
                    </List>
                  </Collapse>
                </div>
              ) : undefined
            )}
            <div>
              <MenuGesDoc
                collapse={collapse}
                open={open}
                toogleCollapse={toogleCollapse}
                handleDrawerOpen={handleDrawerOpen}
              />
            </div>
            <Divider />

            <OptionComponent
              name="Eliminar mi usuario"
              icon={<PersonAddDisabled style={{ color: "white" }} />}
              url="/deleteuserdata/773"
              estadoBarra={open}
              permiso={true}
            />

            <OptionComponent
              name="Cerrar sesión"
              icon={<ExitToAppIcon style={{ color: "white" }} />}
              url="/logout"
              estadoBarra={open}
              permiso={true}
            />
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {typeof props.bodyHeader !== "undefined" && !props.bodyHeader ? (
          <></>
        ) : (
          <div className="LayoutHeader">
            <Typography variant="h6" component="h2" className="Titulo">
              <span>{titulo || ""}</span>
            </Typography>
            {props.header}
          </div>
        )}
        <Container
          maxWidth={props.maxWidth ? props.maxWidth : "lg"}
          className={classes.container}
          style={{ ...props.bodyContainerStyle }}
        >
          <Grid container spacing={3}>
            {props.children}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
